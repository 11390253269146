import React, { useState } from 'react';

interface SearchInputProps {
    placeholder: string;
    onSearchChange: (query: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({ placeholder, onSearchChange }) => {
    const [input, setInput] = useState('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInput(event.target.value);
        onSearchChange(event.target.value);
    };

    const clearInput = () => {
        onSearchChange('');
        setInput('');
    };

    return (
        <div className="w-full relative">
            {input && (
                <button className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700" onClick={clearInput}>✕</button>
            )}
            <input id="search" type="text" className="w-full p-2 pl-5 border border-flame rounded-md bg-gray-200 dark:bg-Night text-DarkNight dark:text-honeydew" placeholder={placeholder} value={input} onChange={handleInputChange} autoComplete='off'/>
        </div>
    );
};

export default SearchInput;
