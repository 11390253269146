import React, { useState, useEffect } from 'react';
import FormModal from '../../../../../../global/modals/formModal';
import { InfoBanner } from '../../../../../../global/ui/banners';
import { fetchArtistPreview, updateUserProfile } from '../../../utlis/services/settingsFunctions';
import { GeneralButton, GeneralButton2 } from '../../../../../../global/ui/buttons';
import { useUser } from '../../../../../../global/storage/userContext';
import { Info, Search } from 'lucide-react';


const SpotifyLink: React.FC = () => {
    const [spotifyShowModal, setSpotifyShowModal] = useState(false);
    const [showSpotifyGuide, setShowSpotifyGuide] = useState(false);
    const [spotifyId, setSpotifyId] = useState('');
    const [spotifyData, setSpotifyData] = useState<any>(null);
    const [message, setMessage] = useState('');
    const { user } = useUser();
    
    useEffect(() => {
        if (user?.spotifyId) {
            setSpotifyId(`https://open.spotify.com/artist/${user.spotifyId}`);
            fetchArtistPreview(user.spotifyId, setMessage, setSpotifyData);
        }
    }, [user?.spotifyId]);

    const handleSaveClick = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (spotifyId === '') {
            setMessage('Please enter a Spotify track URL');
            return;
        }

        const artistId = spotifyId.split('/').pop()?.split('?')[0];

        if (!artistId) {
            setMessage('Invalid Spotify artist URL');
            return;
        }
        updateUserProfile('spotifyId', artistId);
        setSpotifyShowModal(false);
    };

    const handleSpotifyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSpotifyId(event.target.value);
    };

    const handleSpotifyFetch = async () => {
        if (spotifyId === '') {
            setMessage('Please enter a Spotify track URL');
            return;
        }

        const artistId = spotifyId.split('/').pop()?.split('?')[0];

        if (!artistId) {
            setMessage('Invalid Spotify artist URL');
            return;
        }
        await fetchArtistPreview(artistId, setMessage, setSpotifyData);
    };

    return (
        <>
        <FormModal isOpen={spotifyShowModal} onClose={() => setSpotifyShowModal(false)} title="Spotify Link" content={
                <form onSubmit={handleSaveClick} className="space-y-6">
                    {message && <InfoBanner message={message} />}
                    <div className="flex flex-col md:flex-row md:items-center">
                        <label htmlFor="spotifyId" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0 md:ml-20 lg:ml-52 text-black dark:text-white">Artist Spotify Link<span className="text-red-500 text-base ml-1">*</span></label>
                        <div className="flex w-full md:w-3/4">
                            <input type="text" id="spotifyId" name="spotifyId" className="flex-grow border border-flame rounded-l-md bg-gray-200 dark:bg-Night p-2 px-4" value={spotifyId} onChange={handleSpotifyChange} />
                            <button type="button" onClick={handleSpotifyFetch} className="bg-flame hover:bg-lightFlame text-white p-2 rounded-r-lg">
                                <Search />
                            </button>
                        </div>
                    </div>
                    <div className="flex items-center text-sm text-black dark:text-gray-400">
                        <button type="button" onClick={() => setShowSpotifyGuide(!showSpotifyGuide)} className="flex items-center hover:text-flame">
                            <Info size={16} className="mr-1" />
                            How to get the Spotify Artist URL
                        </button>
                    </div>
                    {showSpotifyGuide && (
                        <div className="bg-gray-200 dark:bg-Night p-4 rounded-md text-sm">
                            <h4 className="font-bold mb-2">How to get your Spotify Artist URL:</h4>
                            <ol className="list-decimal list-inside space-y-1">
                                <li>Open Spotify and go to your artist profile.</li>
                                <li>Click on the three dots (...) next to your artist name.</li>
                                <li>Go to "Share" and select "Copy link to artist".</li>
                                <li>Paste the copied URL into the input field above.</li>
                            </ol>
                            <p className="mt-2">The URL should look like: https://open.spotify.com/artist/1234567890abcdef</p>
                        </div>
                    )}
                    <div className="mt-4">
                        {spotifyData && (
                            <div>
                                {spotifyData.images && spotifyData.images.length > 0 && (
                                    <img 
                                        src={spotifyData.images[0].url} 
                                        alt={spotifyData.name ?? 'Artist image'} 
                                        className="w-32 h-32 object-cover rounded-full mx-auto"
                                    />
                                )}
                                {spotifyData.name && <p className="text-center mt-2 font-semibold">{spotifyData.name}</p>}
                            </div>
                        )}
                    </div>
                    <div className="flex justify-end items-center mb-10">
                        <GeneralButton type="button" onClick={() => setSpotifyShowModal(false)} text="Cancel" space={true} />
                        <GeneralButton type="submit" text="Save" space={true}/>
                    </div>
                </form>
            } editing={false} />

            <div className="flex justify-between items-center mb-5 text-sm">
                <p>Spotify Link</p>
                <GeneralButton2 text={user?.spotifyId ? "View" : "Add"} onClick={() => setSpotifyShowModal(true)} />
            </div>
        </>
    )
}

export default SpotifyLink;