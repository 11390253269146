import React, { useState } from 'react';
import { UserFormProps, authOptions } from '../utlis/types/userTypes';
import CustomDropdown from '../../../../global/ui/customDropdown';
import { InfoBanner } from '../../../../global/ui/banners';
import { GeneralButton } from '../../../../global/ui/buttons';

const UserForm: React.FC<UserFormProps> = ({ formData, handleSubmit, handleInputChange, message, closeModal, setMessage, editing }) => {
    const [formError, setFormError] = useState('');
    
    const handleStandardInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessage('');
        handleInputChange(e.target.name, e.target.value);
    };

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
  
        setFormError('');
        handleSubmit(e);
    };

    return (
        <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleFormSubmit(e)} className="space-y-6">
            {message && <InfoBanner message={message} />}
            {formError && <InfoBanner message={formError} />}
            <div className="flex flex-col md:flex-row md:items-center">
                <label htmlFor="firstName" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0 md:ml-20 lg:ml-52 text-black dark:text-white">First Name<span className="text-red-500 text-base ml-1">*</span></label>
                <input type="text" id="firstName" name="firstName" className="border border-flame rounded-md bg-gray-200 dark:bg-Night p-2 px-4 w-full md:w-2/5" value={formData.firstName} onChange={handleStandardInputChange} />
            </div>
            <div className="flex flex-col md:flex-row md:items-center">
                <label htmlFor="lastName" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0 md:ml-20 lg:ml-52 text-black dark:text-white">Last Name<span className="text-red-500 text-base ml-1">*</span></label>
                <input type="text" id="lastName" name="lastName" className="border border-flame rounded-md bg-gray-200 dark:bg-Night p-2 px-4 w-full md:w-2/5" value={formData.lastName} onChange={handleStandardInputChange} />
            </div>
            <div className="flex flex-col md:flex-row md:items-center">
                <label htmlFor="email" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0 md:ml-20 lg:ml-52 text-black dark:text-white">Email<span className="text-red-500 text-base ml-1">*</span></label>
                <input type="text" id="email" name="email" className="border border-flame rounded-md bg-gray-200 dark:bg-Night p-2 px-4 w-full md:w-2/5" value={formData.email} onChange={handleStandardInputChange} autoComplete='off' />
            </div>
            {!editing && (
                <div className="flex flex-col md:flex-row md:items-center">
                    <label htmlFor="password" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0 md:ml-20 lg:ml-52 text-black dark:text-white">Password<span className="text-red-500 text-base ml-1">*</span></label>
                    <input type="password" id="password" name="password" className="border border-flame rounded-md bg-gray-200 dark:bg-Night p-2 px-4 w-full md:w-2/5" value={formData.password} onChange={handleStandardInputChange} />
                </div>
            )}
            <div className="flex flex-col md:flex-row md:items-center">
                <label htmlFor="account_type" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0 md:ml-20 lg:ml-52 text-black dark:text-white">Account Type</label>
                <CustomDropdown value={formData.account_type} onChange={(value) => handleInputChange('account_type', value)} options={authOptions} id="account_type"/>
            </div>
            <div className="flex justify-end items-center mb-10">
                <GeneralButton type="button" onClick={closeModal} text="Cancel" space={true} />
                <GeneralButton type="submit" text="Submit" space={true}/>
            </div>
        </form>
    );
}

export default UserForm;
