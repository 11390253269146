import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { updateUserSettings } from '../../pages/main/components/utlis/services/settingsFunctions';
import { setUserData } from './sessionStorage';

type User = {
  id: number;
  firstName: string;
  lastName: string;
  auth: string;
  verified: boolean;
  artistName: string;
  spotifyId: string | null;
  settings: {
    DarkMode: boolean;
    [key: string]: any;
  };
};

interface SignupFormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

type UserContextType = {
  user: User | null;
  setUser: (user: User | null) => void;
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  signup: (formData: SignupFormData) => Promise<User>;
  forgotPassword: (email: string) => Promise<void>;
  loading: boolean;
  theme: 'light' | 'dark';
  toggleTheme: () => void;
};

const UserContext = createContext<UserContextType | null>(null);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [theme, setTheme] = useState<'light' | 'dark'>('dark');

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    if (user) {
      setUser({
        ...user,
        settings: { ...user.settings, DarkMode: newTheme === 'dark' }
      });
      updateUserSettings({ DarkMode: newTheme === 'dark' });
    }
  };

  useEffect(() => {
    if (user && user.settings && 'DarkMode' in user.settings) {
      setTheme(user.settings.DarkMode ? 'dark' : 'light');
    } else {
      setTheme('dark'); // Default to dark theme if user settings are not available
    }
  }, [user]);

  useEffect(() => {
    let debounceCheck: NodeJS.Timeout | undefined;
  
    const checkUserSession = async () => {
      setLoading(true);
      try {
        const response = await fetch('/api/user/session', {
          credentials: 'include',
        });
        const data = await response.json();
        if (response.ok) {
          setUser(data.user);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Error checking user session:', error);
        setUser(null);
      }
      setLoading(false);
    };
  
    clearTimeout(debounceCheck);
    debounceCheck = setTimeout(() => {
      checkUserSession();
    }, 500);
  
    return () => clearTimeout(debounceCheck);
  }, []);  

  const login = async (email: string, password: string) => {
    try {
      const response = await fetch('/api/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();
      if (response.ok) {
        setUser(data.user);
      } else {
        throw new Error(data.error);
      }
    } catch (error: any) {
      throw new Error(error.message || 'Login failed due to server error');
    }
  };

  const logout = async () => {
    try {
      await fetch('/api/user/logout', {
        method: 'POST',
        credentials: 'include',
      });
      setUser(null);
      setUserData('spotifyData', null);
    } catch (error: any) {
      console.error('Login error:', error.message);
    }
  };

  const signup = async (formData: SignupFormData) => {
    try {
      const response = await fetch('/api/user/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ formData }),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to sign up');
      }
      const data = await response.json();
      return data.user;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      const response = await fetch('/api/user/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to send password reset email');
      }
      const data = await response.json();
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  return (
    <UserContext.Provider value={{ user, setUser, login, logout, signup, forgotPassword, loading, theme, toggleTheme }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => { const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
