import { Writer } from '../types/wrtiterTypes';
import { getUserData, setUserData } from '../../../../../global/storage/sessionStorage';
import { 
    AddWriterParams, RemoveWriterParams, HandleRoleChangeParams,
    HandleRTCChangeParams, HandleSplitChangeParams, WorkWritersDT, 
    songSubmitParams, SongFormProps, SongDT
} from '../types/songTypes';


export const fetchWritersDropdown = async (setUserWriters: (writers: Writer[]) => void) => {
    try {
        const response = await fetch('/api/writers/writers-dropdown');
        const data = await response.json();
        setUserWriters(data.writers);
    } catch (error) {
        console.error('Error fetching writers:', error);
    }
};

export const handleSplit = (writers: WorkWritersDT[], setError: (msg: string) => void) => {
    const totalSplit = writers.reduce((acc, curr) => acc + Number(curr.split), 0);
    if (totalSplit > 100) {
        setError('Total splits exceed 100%');
    } else {
        setError('');
    }
};

export const handleEvenSplit = (formData: SongFormProps['formData'], setFormData: (newFormData: any) => void) => {
    const numWriters = formData.WorkWriters.length;
    if (numWriters === 0) return;

    const evenSplit = Math.floor(100 / numWriters);
    let totalAssigned = evenSplit * numWriters;
    const leftover = 100 - totalAssigned;

    const updatedWriters = formData.WorkWriters.map((writer, index) => {
        if (index === numWriters - 1) {
            return {...writer, split: evenSplit + leftover};
        } else {
            return {...writer, split: evenSplit};
        }
    });

    setFormData((prev: SongFormProps['formData']) => ({...prev, WorkWriters: updatedWriters }));
};

export const handleAddWriter = ({ selectedWriter, userWriters, formDataWriters, setFormData, setUserWriters, setSelectedWriter }: AddWriterParams) => {
    if (selectedWriter) {
        const selectedWriterObj = userWriters.find(writer =>`${writer.firstName} ${writer.lastName}` === selectedWriter);

        if (selectedWriterObj) {
            const newWriter: WorkWritersDT = {
                writerId: selectedWriterObj.id,
                Writer: {
                    id: selectedWriterObj.id,
                    firstName: selectedWriterObj.firstName,
                    lastName: selectedWriterObj.lastName,
                },
                role: '',
                split: 0,
                rtc: selectedWriterObj.isUserAccount ? true : false,
            };

            setFormData((prev: SongFormProps['formData']) => ({...prev, WorkWriters: [...formDataWriters, newWriter],}));
            setUserWriters(userWriters.filter(writer => `${writer.firstName} ${writer.lastName}` !== selectedWriter));
        }
        setSelectedWriter('');
    }
};

export const handleRemoveWriter = ({ index, formDataWriters, setFormData, userWriters, setUserWriters }: RemoveWriterParams) => {
    const writerToRemove = formDataWriters[index];
    const updatedWriters = formDataWriters.filter((_, i) => i !== index);
    setFormData((prev: SongFormProps['formData']) => ({ ...prev, WorkWriters: updatedWriters }));

    // Check if the writer is already in the userWriters list before adding
    const fullWriterInfo = userWriters.find(writer => writer.id === writerToRemove.writerId);

    if (!fullWriterInfo) {
        setUserWriters([
            ...userWriters, {
            id: writerToRemove.writerId, 
            firstName: writerToRemove.Writer.firstName, 
            lastName: writerToRemove.Writer.lastName, 
            Pro: "", 
            ipiNumber: "",
            isUserAccount: writerToRemove.rtc // Preserve the isUserAccount information
        }
    ]);
    }
};

export const handleRoleChange = ({ index, newRole, formDataWriters, setFormData }: HandleRoleChangeParams) => {
    const updatedWriters = formDataWriters.map((writer, i) => {
        if (i === index) {
            return { ...writer, role: newRole };
        }
        return writer;
    });
    setFormData((prev: SongFormProps['formData']) => ({ ...prev, WorkWriters: updatedWriters }));
};

export const handleRTCChange = ({ index, rtc, formDataWriters, setFormData }: HandleRTCChangeParams) => {
    const updatedWriters = formDataWriters.map((writer, i) => {
        if (i === index) {
            return { ...writer, rtc };
        }
        return writer;
    });
    setFormData((prev: SongFormProps['formData']) => ({ ...prev, WorkWriters: updatedWriters }));
};

export const handleInputChange = (field: string, value: string | WorkWritersDT[], setFormData: (newData: any) => void) => {
    if (Array.isArray(value)) {
        setFormData((prev: SongFormProps['formData']) => ({ ...prev, [field]: value }));
    } else {
        setFormData((prev: SongFormProps['formData']) => ({ ...prev, [field]: value }));
    }
};

export const handleSplitChange = ({ index, newSplit, formDataWriters, setFormData }: HandleSplitChangeParams) => {
    const updatedWriters = formDataWriters.map((writer, i) => {
        if (i === index) {
            return { ...writer, split: newSplit === '' ? '' : Number(newSplit) };
        }
        return writer;
    });
    setFormData((prev: SongFormProps['formData']) => ({ ...prev, WorkWriters: updatedWriters }));
};

export const handleSubmit = async ({e, formData, setError, setSuccess, editing, selectedSongId, navigate}: songSubmitParams) => {
    e.preventDefault();
    setError("");

    if (!formData.title) {
        setError("Song title is required.");
        return;
    } 
    
    if (!formData.performingArtist) {
        setError("A Performing Artist is required");
        return;
    }

    if (formData.WorkWriters.length === 0) {
        setError("At least one writer is required.");
        return;
    }
    
    const missingRoles = formData.WorkWriters.some((writer: WorkWritersDT) => !writer.role || writer.role.trim() === '');
    if (missingRoles) {
        setError("Each writer must have a role assigned.");
        return;
    }

    const totalSplit = formData.WorkWriters.reduce((sum: number, writer: WorkWritersDT) => sum + Number(writer.split), 0);
    if (totalSplit !== 100) {
        setError("The total writer split must equal 100%.");
        return;
    }

    const endpoint = editing ? `/api/songs/${selectedSongId}` : '/api/songs/create-song';
    const method = editing ? 'PUT' : 'POST';

    try {
        const response = await fetch(endpoint, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(formData),
        });

        if (response.ok) {
            setSuccess(editing ? "Song updated successfully." : "Song added successfully.");
            setTimeout(() => {
                navigate('/songs');
            }, 500);
            navigate('/songs');
        } else {
            setError(`Failed to ${editing? "update Song entry." : "create Song entry."} Please try again.`);
        }
    } catch (error: any) {
        setError("An error occurred while saving the Song: " + error.message);
    }
};

export const fetchSongs = async (page: number, pageSize:number, searchQuery:string ,setLoading: (newData: any) => void, setTotalPages: (newData: any) => void, setSongsData: (newData: any) => void, userId: string) => {
    setLoading(true);
    try {
        const response = await fetch(`/api/songs/users-songs?page=${page}&pageSize=${pageSize}&search=${encodeURIComponent(searchQuery)}&userId=${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        const data = await response.json();
        if (response.ok) {
            setSongsData(data.songs || []);
            setTotalPages(data.totalPages || 0);
        } else {
            console.error(data.message);
            setSongsData([]);
            setTotalPages(0);
        }
    } catch (error) {
        console.error('Error fetching songs:', error);
    }
    setLoading(false);
};

export const fetchSongDetails = async (songId: string, setError: (newData: string) => void) => {
    try {
        const response = await fetch(`/api/songs/songs-Details?songId=${songId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        const data = await response.json();
        if (response.ok) {
            return data;
        } else {
            console.error(data.message);
        }
    } catch (error) {
        setError('Failed to get song Details');
        console.error('Error fetching detail:', error);
    }

}

export const deleteSong = async (selectedSongId: number | null) => {
    try {
        const response = await fetch(`/api/songs/delete/${selectedSongId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        return response.ok;
    } catch (error) {
        console.error('Error deleting song:', error);
    }
}

export const fetchSpotifySearch = async (
    trackUrl: string, 
    setFormData: React.Dispatch<React.SetStateAction<any>>, 
    setError: React.Dispatch<React.SetStateAction<string>>,
    setPreviewUrl: React.Dispatch<React.SetStateAction<string | null>>,
    setAlbumArtUrl: React.Dispatch<React.SetStateAction<string | null>>) => {
    try {
        // Extract track ID from URL
        const trackId = trackUrl.split('/').pop()?.split('?')[0];
        
        if (!trackId) {
            setError('Invalid Spotify track URL');
            return;
        }

        const response = await fetch(`/api/songs/spotify/search?trackId=${encodeURIComponent(trackId)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        const data = await response.json();
        
        if (data.name) {
            setFormData((prevData: SongDT) => ({
                ...prevData,
                title: data.name,
                performingArtist: data.artists.map((artist: any) => artist.name).join(', '),
                duration: msToTime(data.duration_ms)
            }));
            setPreviewUrl(data.preview_url);
            setAlbumArtUrl(data.image_url);
        } else {
            setError('No results found on Spotify');
        }

    } catch (error) {
        console.error('Error fetching Spotify track:', error);
        setError('Error fetching Spotify track');
    }
}

export const fetchSpotifyData = async (spotifyId: string, setSpotifyData: (newData: any) => void, setLoading: (newData: any) => void) => {
    setLoading(true);
    
    // Check if data exists in session storage
    const cachedData = getUserData('spotifyData');
    if (cachedData) {
        setSpotifyData(cachedData);
        setLoading(false);
        return;
    }

    try {
        const response = await fetch(`/api/user/spotify/search?artistId=${encodeURIComponent(spotifyId)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });

        const data = await response.json();
        if (response.ok) {
            setSpotifyData(data.artist);
            // Store the data in session storage
            setUserData('spotifyData', data.artist);
        } else {
            console.error(data.message);
        }
    } catch (error) {
        console.error('Error fetching Spotify data:', error);
    }
    setLoading(false);
};

export const fetchRecentlyAddedSongs = async (limit: number, setSongsData: (newData: any) => void, setLoading: (newData: any) => void, setTotalCount: (newData: any) => void) => {
    setLoading(true);
    try {
        const response = await fetch(`/api/songs/recently-added?limit=${limit}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include', 
        });

        const data = await response.json();
        if (response.ok) {
            setSongsData(data.songs || []);
            setTotalCount(data.totalCount);
        } else {
            console.error(data.message);
            setSongsData([]);
        }
    } catch (error) {
        console.error('Error fetching recently added songs:', error);
    }

    setLoading(false);
};

// Helper function to convert milliseconds to HH:MM:SS format
const msToTime = (duration: number) => {
    const seconds = Math.floor((duration / 1000) % 60);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    return [
        hours.toString().padStart(2, '0'),
        minutes.toString().padStart(2, '0'),
        seconds.toString().padStart(2, '0')
    ].join(':');
};