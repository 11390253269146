import { Writer } from "./wrtiterTypes";
import { useUser } from "../../../../../global/storage/userContext";

export interface WritersDT {
    id: number;
    firstName: string;
    lastName: string;
}

export interface WorkWritersDT {
    writerId: number; 
    Writer: WritersDT;
    role: string;
    rtc: boolean;
    split: number;
}

export interface SongDT {
    id: number;
    title: string;
    performingArtist: string;
    iswc?: string;
    language?: string;
    status?: string;
    duration: string;
    createdAt?: string;
    WorkWriters: WorkWritersDT[];
}

export interface SongRowProps {
    song: SongDT;
    handleEdit?: (writer: number) => void;
    openDeleteModal?: (id: number) => void;
}

export type WriterSongForm = {
    id: number;
    firstName: string;
    lastName: string;
    role: string;
    split: number;
    controlled: boolean;
}

export interface SongFormProps {
    formData: SongDT;
    handleSubmit: React.FormEventHandler<HTMLFormElement>;
    handleInputChange: (field: string, value: string | WorkWritersDT[]) => void;
    error: string;
    success: string;
    closeModal: () => void;
}

export const initialSongFormData: SongFormProps['formData'] = {
    id: 0,
    title: "",
    performingArtist: "",
    iswc: "",
    language: "",
    duration: "",
    WorkWriters: []
};

export interface AddWriterParams {
    selectedWriter: string;
    userWriters: Writer[];
    formDataWriters: WorkWritersDT[];
    setFormData: (newFormData: any) => void;
    setUserWriters: (writers: Writer[]) => void;
    setSelectedWriter: (writer: string) => void;
}

export interface RemoveWriterParams {
    index: number;
    formDataWriters: WorkWritersDT[];
    setFormData: (newFormData: any) => void;
    userWriters: Writer[];
    setUserWriters: (writers: Writer[]) => void;
}

export interface HandleRoleChangeParams {
    index: number;
    newRole: string;
    formDataWriters: WorkWritersDT[];
    setFormData: (newFormData: any) => void;
}

export interface HandleRTCChangeParams {
    index: number;
    rtc: boolean;
    formDataWriters: WorkWritersDT[];
    setFormData: (newFormData: any) => void;
}

export interface HandleSplitChangeParams {
    index: number;
    newSplit: number | '';
    formDataWriters: WorkWritersDT[];
    setFormData: (newFormData: any) => void;
}

export interface songSubmitParams {
    e: React.FormEvent;
    formData: SongFormProps['formData'];
    setError: (error: string) => void;
    setSuccess: (success: string) => void;
    editing: boolean;
    selectedSongId: string | null;
    navigate: (path: string) => void
}

export const useSongHeaders = () => {
    const { user } = useUser();

    const baseHeaders = [
        { name: "Name" },
        { name: "Performer(s)" },
        { name: "Writer(s)" },
        { name: "Status"},
    ];

    if (user?.auth === "user") {
        baseHeaders.push({ name: "Actions" });
    }

    return baseHeaders;
};

export const RoleOptions = [
    { label: 'Author', value: 'Author' },
    { label: 'Composer', value: 'Composer' },
    { label: 'Author/Composer', value: 'Author/Composer' },
];
