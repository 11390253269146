import React, { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from './storage/userContext';
import LoadingScreen from './pages/loadingScreen';

interface PublicRouteProps {
  children: ReactNode;
  redirectPath: string;  // Path to redirect if the user is logged in
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children, redirectPath }) => {
  const { user, loading } = useUser();
  const location = useLocation();

  if (loading) {
    return <LoadingScreen />;
  }

  if (user) {
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default PublicRoute;
