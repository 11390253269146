import React, { useState } from "react";
import { useUser } from "../../../../global/storage/userContext";
import { useNavigate } from "react-router-dom";
import { sendEmailVerification } from "../utlis/services/settingsFunctions";
import { InfoBanner } from "../../../../global/ui/banners";
import { SectionContainer } from "../../../../global/ui/containers";


const UnverifiedDashboard: React.FC = () => {
	const { user } = useUser();
	const [message, setMessage] = useState("");
	const navigate = useNavigate();

	const handleResendVerification = async () => {
		try {
			if (user?.id) {
				await sendEmailVerification(user.id, setMessage);
			} else {
				setMessage("An error occurred. Please try again later.");
			}
		} catch (error) {
			console.error('Error sending verification email:', error);
			setMessage("An error occurred. Please try again later.");
		}
	};

	return ( 
		<SectionContainer title="Dashboard">
			<InfoBanner message={user?.verified ? "" : "Please verify your email address to access all features."} actionLabel="Resend Verification Email"
				onAction={handleResendVerification}
			/>
			<InfoBanner message={message} />
			{user?.verified ? (
				<p className="text-honeydew">Welcome to your dashboard!</p>
			) : (
				<div className="text-black dark:text-honeydew">
					<h2 className="text-2xl font-bold mb-4">Email Verification Required</h2>
					<p>
						To access your full dashboard and all features of our platform, 
						please verify your email address. We've sent a verification link 
						to your registered email address.
					</p>
					<p className="mt-4">
						If you haven't received the email, please check your spam folder 
						or use the "Resend Verification Email" button above.
					</p>
				</div>
			)}
		</SectionContainer>
	);
};

export default UnverifiedDashboard;
