import React, { useState } from 'react';
import { InfoBanner } from '../../../../global/ui/banners';
import { GeneralButton } from '../../../../global/ui/buttons';
import { JobFormProps } from '../utlis/types/jobtypes';

const JobForm: React.FC<JobFormProps> = ({ formData, handleSubmit, handleInputChange, error, success, closeModal }) => {
    const [formError, setFormError] = useState('');

    const handleStandardInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        handleInputChange(e.target.name, e.target.value);
    };

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(formData.name === '') {
            setFormError('Job Name Required');
            return;
        }
        if(formData.description === '') {
            setFormError('Description Required');
            return;
        }
        if(formData.type === '') {
            setFormError('Job Type Required');
            return;
        }
        handleSubmit(e);
    };

    return (
        <form onSubmit={handleFormSubmit} className="space-y-6">
            {error && <InfoBanner message={error} />}
            {formError && <InfoBanner message={formError} />}
            {success && <InfoBanner message={success} />}
            <div className="flex flex-col md:flex-row md:items-center">
                <label htmlFor="name" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0 md:ml-20 lg:ml-52 text-black dark:text-white">Name<span className="text-red-500 text-base ml-1">*</span></label>
                <input type="text" id="name" name="name" className="border border-flame rounded-md bg-gray-200 dark:bg-Night p-2 px-4 w-full md:w-2/5" value={formData.name} onChange={handleStandardInputChange} />
            </div>
            <div className="flex flex-col md:flex-row md:items-center">
                <label htmlFor="description" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0 md:ml-20 lg:ml-52 text-black dark:text-white">Description<span className="text-red-500 text-base ml-1">*</span></label>
                <input type="text" id="description" name="description" className="border border-flame rounded-md bg-gray-200 dark:bg-Night p-2 px-4 w-full md:w-2/5" value={formData.description} onChange={handleStandardInputChange} />
            </div>
            <div className="flex flex-col md:flex-row md:items-center">
                <label htmlFor="type" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0 md:ml-20 lg:ml-52 text-black dark:text-white">Type<span className="text-red-500 text-base ml-1">*</span></label>
                <input type="text" id="type" name="type" className="border border-flame rounded-md bg-gray-200 dark:bg-Night p-2 px-4 w-full md:w-2/5" value={formData.type} onChange={handleStandardInputChange} />
            </div>
            <div className="flex justify-end items-center mb-10">
                <GeneralButton type="button" onClick={closeModal} text="Cancel" space={true} />
                <GeneralButton type="submit" text="Submit" space={true}/>
            </div>
        </form>
    );
}

export default JobForm;
