import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../global/storage/userContext';
import { InfoBanner, LogoBanner } from '../../global/ui/banners';

const ForgotPasswordPage: React.FC = () => {
  const { forgotPassword } = useUser();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleForgotPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await forgotPassword(email);
      setMessage('If a user with this email exists, a password reset link will be sent.');
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    } catch (error: any) {
      setMessage(error.message);
    }
  }

  return (
    <div className="min-h-screen bg-black flex items-center justify-center p-6">
        <div className="w-full max-w-md">
          <div className="bg-DarkNight p-8 rounded-lg shadow-md">
              <h1 className="text-2xl font-bold mb-2 text-center text-honeydew">Forgot your password?</h1>
              <div className="text-honeydew text-sm mb-8 text-center">
                Enter your email address and we will send you a link to reset your password.
              </div>
              <form onSubmit={handleForgotPassword} className="space-y-4">
                  <div>
                      <label htmlFor="email" className="block text-sm font-medium text-honeydew">Email</label>
                      <input type="email" id="email" value={email} onChange={(e) => {setEmail(e.target.value); setMessage('');}} className="w-full px-3 py-2 border rounded-md" placeholder="email" autoComplete="off" required />
                  </div>
                  {message && <div className="text-honeydew text-sm pt-5 text-center">{message}</div>}
                  <button type="submit" className="w-full py-2 rounded-md bg-flame hover:bg-red-600 text-white font-medium">Send reset email</button>
                  <div className="flex justify-center">
                    <div className="text-honeydew text-md mb-8 mt-8 text-center">
                        Remember your password? <a href="/login" className="text-flame hover:text-blue-600">Login</a>
                    </div>
                </div>
              </form>
              <LogoBanner />
          </div>
        </div>
    </div>
  );
};  

export default ForgotPasswordPage;
    