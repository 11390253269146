import React, { useState, useEffect } from "react";
import { Writer, writerHeaders } from "../utlis/types/wrtiterTypes";
import WriterForm from "./writersForm";
import WriterDataTable from "./writersDataTable";
import DeleteModal from "../../../../global/modals/deleteModal";
import Pagination from "../../../../global/dataTable/pagination";
import DynamicTable from "../../../../global/dataTable/table";
import SearchInput from "../../../../global/dataTable/searchInput";
import FormModal from "../../../../global/modals/formModal";
import { deleteWriter, fetchWriters, formSubmit } from "../utlis/services/writerpageFunctions";
import { GeneralButton, TutorialButton } from "../../../../global/ui/buttons";
import { SectionContainer } from "../../../../global/ui/containers";

const WritersScreen: React.FC = () => {
    const [writersData, setWritersData] = useState<Writer[]>([]);
    const [selectedWriterId, setSelectedWriterId] = useState<number | null>(null);

    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [totalPages, setTotalPages] = useState(0);

    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [formData, setFormData] = useState({ firstName: "", lastName: "", Pro: "", caeIpiNumber: ""});

    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            fetchWriters(currentPage, pageSize, searchQuery, setLoading, setTotalPages, setWritersData);
        }, 600);
        return () => clearTimeout(debounceTimer);
    }, [searchQuery, currentPage, pageSize]);

    const handleDelete = async () => {
        closeModal();
        if (!selectedWriterId) return;
        const response = await deleteWriter(selectedWriterId);

        if (response === true) {
            fetchWriters(currentPage, pageSize, searchQuery, setLoading, setTotalPages, setWritersData);
        }
    };

    const handleSearchChange = (query: string) => {
        setSearchQuery(query);
        setCurrentPage(1);
    };

    const handleFormSubmit = async (e: React.FormEvent) => {
        setError("");
        setSuccess("");
        e.preventDefault();
        if(!formData.firstName || !formData.lastName) {
            setError("First Name and Last Name are required.");
            return;
        }
    
        const endpoint = editing ? `/api/writers/${selectedWriterId}` : '/api/writers/create-writer';
        const method = editing ? 'PUT' : 'POST'; 
        const response = await formSubmit(formData, setError, endpoint, method)

        if (response === true) {
            fetchWriters(currentPage, pageSize, searchQuery, setLoading, setTotalPages, setWritersData);
            setSuccess(editing ? "Writer updated successfully." : "Writer added successfully.");
            setTimeout(() => {
                closeModal();
                setFormData({ firstName: "", lastName: "", Pro: "", caeIpiNumber: "" });
            }, 800);
        } else {
            setError("Failed to add/update writer. Please try again.");
        }
    };

    const handleInputChange = (field: string, value: string) => {
        setFormData(prevState => ({ ...prevState, [field]: value }));
        if (error) setError("");
    };

    const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPageSize(Number(event.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handleEdit = (writer: Writer) => {
        openFormModal(writer);
    };

    const openDeleteModal = (writerId: number) => {
        setSelectedWriterId(writerId);
        setDeleteModalOpen(true);
    };

    const openFormModal = (writer?: Writer) => {
        if (writer) {
            setEditing(true);
            setSelectedWriterId(writer.id);
            setFormData({
                firstName: writer.firstName,
                lastName: writer.lastName,
                Pro: writer.Pro,
                caeIpiNumber: writer.ipiNumber
            });
        } else {
            setEditing(false);
            setFormData({ firstName: "", lastName: "", Pro: "", caeIpiNumber: "" });
        }
        setIsFormModalOpen(true);
    };
    
    const closeModal = () => {
        setDeleteModalOpen(false);
        setIsFormModalOpen(false);
        setError("");
        setSuccess("");
    };

    return (
        <SectionContainer title="Writers list" rightElement={<GeneralButton onClick={() => openFormModal()} text="Add a Writer +"/>}>
            <FormModal isOpen={isFormModalOpen} onClose={closeModal} title={editing ? 'Edit Writer' : 'Add Writer'} editing={editing} 
                content={<WriterForm formData={formData} handleSubmit={handleFormSubmit} handleInputChange={handleInputChange} error={error} success={success} closeModal={closeModal}/>} 
            />
            <DeleteModal isOpen={isDeleteModalOpen} onClose={closeModal} onConfirm={handleDelete}><p className="mt-1">Are you sure you want to delete this writer?</p></DeleteModal>
            <div className="overflow-x-auto mt-3">
                <SearchInput placeholder="Search writers..." onSearchChange={(query) => handleSearchChange(query)} />
                <div className="mb-3"></div>
                <DynamicTable data={writersData} headers={writerHeaders} renderRow={(writer, index) => (
                    <WriterDataTable key={index} writer={writer} handleEdit={() => handleEdit(writer)} openDeleteModal={() => openDeleteModal(writer.id)}/>)}
                    loading={loading}
                />
                {writersData.length > 0 && ( 
                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} onPageSizeChange={handlePageSizeChange} pageSize={pageSize} />
                )}
            </div>        
        </SectionContainer>
    );
};
  
export default WritersScreen;  
