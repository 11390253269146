import React, { useState } from 'react';
import CustomDropdown from '../../../global/ui/customDropdown';
import { ProOptions } from '../../main/components/utlis/types/wrtiterTypes';

interface SocietyStepProps {
  userId: number;
  handleNextStep: () => void;
  onSkip: () => void;
}

const SocietyStep: React.FC<SocietyStepProps> = ({ userId, handleNextStep, onSkip }) => {
  const [hasSociety, setHasSociety] = useState<string>('');
  const [selectedSociety, setSelectedSociety] = useState<string>('');
  const [societyNumber, setSocietyNumber] = useState<string>('');

  // Add this function to handle the society number input
  const handleSocietyNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Only allow digits and limit the length to 11
    const sanitizedValue = value.replace(/\D/g, '').slice(0, 11);
    setSocietyNumber(sanitizedValue);
  };

  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSocietyChange = (value: string) => {
    setSelectedSociety(value);
    setSocietyNumber('');
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setMessage('');

    try {
      const response = await fetch('/api/user/update-society', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          society: selectedSociety,
          societyNumber,
        }),
      });

      if (response.ok) {
        setMessage('Society information updated successfully.');
        handleNextStep();
      } else {
        const data = await response.json();
        setMessage(data.error || 'Failed to update society information.');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <p className="text-sm text-honeydew mb-4 mt-5">Are you already registered with a music society?</p>
      <div className="flex items-center justify-center space-x-4">
        <button onClick={() => {setHasSociety('yes'); setMessage('')}} className={`px-4 py-2 rounded ${hasSociety === 'yes' ? 'bg-flame text-white' : 'bg-gray-200 text-black'}`}>
          Yes
        </button>
        <button onClick={() => {setHasSociety('no'); setMessage('')}} className={`px-4 py-2 rounded ${hasSociety === 'no' ? 'bg-flame text-white' : 'bg-gray-200 text-black'}`}>
          No
        </button>
      </div>

      {hasSociety === 'yes' && (
        <div className="space-y-4">
          <CustomDropdown value={selectedSociety} onChange={handleSocietyChange} options={ProOptions} id="society" placeholder="Select your music society" />
          {selectedSociety && (
            <div>
              <input id="societyNumber" type="text" inputMode="numeric" pattern="[0-9]*"
                minLength={9} maxLength={11} value={societyNumber} onChange={handleSocietyNumberChange}
                className="w-full px-3 py-2 border rounded-md" placeholder={`Enter your ${selectedSociety.split(' - ')[1]} number (9-11 digits)`}
              />
            </div>
          )}
          <button onClick={handleSubmit} disabled={isLoading || !selectedSociety || societyNumber.length < 9} className="w-full px-4 py-2 bg-flame text-white rounded disabled:opacity-50">
            {isLoading ? 'Updating...' : 'Submit'}
          </button>
        </div>
      )}

      {hasSociety === 'no' && (
        <div>
          <p className="text-sm text-honeydew mb-4">No problem! You can still use our service without being registered with a music society.</p>
          <button onClick={onSkip} className="w-full px-4 py-2 bg-flame text-white rounded">
            Continue
          </button>
        </div>
      )}

      {message && <p className="text-sm text-red-500">{message}</p>}
    </div>
  );
};

export default SocietyStep;