import React from 'react';

interface GeneralButtonProps {
  type?: "button" | "submit" | "reset";
  onClick?: () => void;
  text?: string;
  space?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  toggleValue?: boolean;
}

export const GeneralButton: React.FC<GeneralButtonProps> = ({ type, onClick, text, space, disabled, icon }) => {
  return (
    <>
      <div className="flex space-x-2">
          <button type={type} onClick={onClick} className={`p-2 rounded-md ${disabled ? 'bg-gray-600 cursor-not-allowed' : 'bg-flame hover:bg-lightFlame'} text-white w-full md:w-auto`} disabled={disabled}>
              {icon && <div>{icon}</div>}{text}
          </button>
      </div>
      {space && <div className="w-2"></div>}
    </>
  );
};

export const GeneralButton2: React.FC<GeneralButtonProps> = ({ type, onClick, text, space, disabled, icon }) => {
  return (
    <>
      <div className="flex space-x-2">
        <button type={type} onClick={onClick} className={`w-28 ml-1 md:ml-0 md:w-24 p-1 md:p-2 rounded-xl ${disabled ? 'bg-gray-600 cursor-not-allowed' : 'bg-flame hover:bg-lightFlame'} text-white flex items-center justify-center`} disabled={disabled}>
            {icon && <div>{icon}</div>}{text}
        </button> 
      </div>
      {space && <div className="w-2"></div>}  
    </>
  );
};

export const ToggleButton: React.FC<GeneralButtonProps> = ({ onClick, space, disabled, icon, toggleValue }) => {
  return (
    <>
      <div className="flex space-x-2">
      <label className="flex items-center cursor-pointer">
          <div className="relative md:mr-4">
            <input id="darkMode" type="checkbox" className="sr-only" checked={toggleValue} onChange={onClick} />
            <div className={`w-10 h-4 rounded-full shadow-inner ${toggleValue ? 'bg-eerieBlack' : 'bg-white'}`}></div>
            <div className={`absolute w-6 h-6 rounded-full shadow -left-1 -top-1 transition ${toggleValue ? 'transform translate-x-full bg-flame' : 'bg-flame'}`}></div>
          </div>
        </label>
      </div>
      {space && <div className="w-2"></div>}
    </>
  );
}

export const TutorialButton: React.FC<GeneralButtonProps> = ({ onClick }) => {
  return (
    <button type="button" onClick={onClick} className="px-2 py-1 ml-2 text-flame hover:text-lightFlame hover:underline font-bold border-flame border rounded-lg">
        ?
    </button>
  );
};
