import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { MonthlyLineChartProps } from '../types/GraphTypes';

const QuarterlyBarChart: React.FC<MonthlyLineChartProps> = ({ data }) => (
  <ResponsiveContainer width="100%" height="100%">
    <BarChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip 
        wrapperStyle={{ backgroundColor: 'transparent', borderColor: 'transparent' }} // Custom styles
        contentStyle={{ backgroundColor: 'rgba(0,0,0,0.75)', borderColor: 'transparent' }} // Inner content styles
        cursor={false} // Disable cursor rectangle
      />
      {/* <Legend /> */}
      <Bar dataKey="earnings" fill="#e75113" />
    </BarChart>
  </ResponsiveContainer>
);

export default QuarterlyBarChart;
