import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LogoBanner } from '../../global/ui/banners';

const ResetPasswordPage: React.FC = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isTokenValid, setIsTokenValid] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();
  const verificationAttempted = useRef(false);

  useEffect(() => {
    const verifyToken = async () => {
      if (verificationAttempted.current) return;
      verificationAttempted.current = true;

      try {
        const response = await fetch(`/api/user/reset-password/${token}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          setIsTokenValid(true);
        } else {
          setMessage('This password reset link is invalid or has expired.');
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        setMessage('An error occurred. Please try again later.');
      }
    };
    verifyToken();
  }, [token]);

  const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }
    try {
      const response = await fetch(`/api/user/reset-password/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }),
      });
      if (response.ok) {
        navigate('/login', { state: { message: 'Password reset successful. Please log in with your new password.' } });
      } else {
        const data = await response.json();
        setMessage(data.error || 'Failed to reset password. Please try again.');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      setMessage('An error occurred. Please try again later.');
    }
  };

  if (!isTokenValid) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center p-6">
        <div className="w-full max-w-md bg-DarkNight p-8 rounded-lg shadow-md">
          <h1 className="text-2xl font-bold mb-4 text-center text-honeydew">Password Reset</h1>
          <p className="text-honeydew text-center">{message}</p>
          <div className="flex justify-center">
            <button onClick={() => navigate('/login')} className="px-6 py-2 mt-10 border border-transparent text-base font-medium bg-flame hover:bg-honeydew text-white hover:text-flame">
              Go Back To Login
            </button>
          </div>
        </div>
        <div className="fixed bottom-0 right-0 z-50 p-4 md:p-8">
            <div className="text-3xl text-honeydew flex items-center">
              <img src="/assets/fidiaLogo2.png" alt="thefidia" className="h-10 mr-2" />
              <h1 className="flex items-center" style={{ fontFamily: 'serifReclare, serif' }}> theFidia </h1>
            </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black flex items-center justify-center p-6">
      <div className="w-full max-w-md">
        <div className="bg-DarkNight p-8 rounded-lg shadow-md">
          <h1 className="text-2xl font-bold mb-2 text-center text-honeydew">Reset Password</h1>
          <form onSubmit={handleResetPassword} className="space-y-4">
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-honeydew">New Password</label>
              <input type="password" id="password" value={password} onChange={(e) => {setPassword(e.target.value); setMessage('');}} className="w-full px-3 py-2 border rounded-md" placeholder="New password" required />
            </div>
            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-honeydew">Confirm New Password</label>
              <input type="password" id="confirmPassword" value={confirmPassword} onChange={(e) => {setConfirmPassword(e.target.value); setMessage('');}} className="w-full px-3 py-2 border rounded-md" placeholder="Confirm new password" required />
            </div>
            {message && <div className="text-red-600 text-sm pt-5 text-center">{message}</div>}
            <button type="submit" className="w-full py-2 rounded-md bg-flame hover:bg-red-600 text-white font-medium">Reset Password</button>
          </form>
          <LogoBanner />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
