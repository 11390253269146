import React from 'react';

interface BasicInfoFormProps {
  formData: {
    artistName: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    tos: boolean;
  };
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleNextStep: () => void;
  isEditable: boolean;
  tosContent: string;
  handleViewTos: () => void;
}

const BasicInfoForm: React.FC<BasicInfoFormProps> = ({ formData, handleChange, handleNextStep, isEditable, tosContent, handleViewTos }) => {
  return (
    <>
      <p className="text-sm text-honeydew mb-5 mt-5">Please fill in the following information to begin creating your account.</p>
      <form className="space-y-6">
        <div>
          <label htmlFor='artistName' className="block text-sm font-medium text-honeydew">Artist Name</label>
          <input id='artistName' className="w-full px-3 py-2 border rounded-md" type="text" name="artistName" value={formData.artistName} onChange={handleChange} autoComplete="off" disabled={!isEditable} />
        </div>
        <div>
          <label htmlFor='firstName' className="block text-sm font-medium text-honeydew">First Name<span className="text-red-500 text-base ml-1">*</span></label>
          <input id='firstName' className="w-full px-3 py-2 border rounded-md" type="text" name="firstName" value={formData.firstName} onChange={handleChange} autoComplete="off" required disabled={!isEditable} />
        </div>
        <div>
          <label htmlFor='lastName' className="block text-sm font-medium text-honeydew">Last Name<span className="text-red-500 text-base ml-1">*</span></label>
          <input id='lastName' className="w-full px-3 py-2 border rounded-md" type="text" name="lastName" value={formData.lastName} onChange={handleChange} autoComplete="off" required disabled={!isEditable} />
        </div>
        <div>
          <label htmlFor='email' className="block text-sm font-medium text-honeydew">Email<span className="text-red-500 text-base ml-1">*</span></label>
          <input id='email' className="w-full px-3 py-2 border rounded-md" type="email" name="email" value={formData.email} onChange={handleChange} autoComplete="off" required disabled={!isEditable} />
        </div>
        <div>
          <label htmlFor='password' className="block text-sm font-medium text-honeydew">Password<span className="text-red-500 text-base ml-1">*</span></label>
          <input id='password' className="w-full px-3 py-2 border rounded-md" type="password" name="password" value={formData.password} onChange={handleChange} autoComplete="off" required disabled={!isEditable} />
        </div>
        <div>
          <label htmlFor='confirmPassword' className="block text-sm font-medium text-honeydew">Confirm Password<span className="text-red-500 text-base ml-1">*</span></label>
          <input id='confirmPassword' className="w-full px-3 py-2 border rounded-md" type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} autoComplete="off" required disabled={!isEditable} />
        </div>
        <div className="text-gray-600 text-sm">
          <label className="flex items-center">
            <input type="checkbox" checked={formData.tos} name="tos" onChange={handleChange} disabled={!isEditable} className="mr-2" />
            I have read and accepted the{' '}
            <span onClick={handleViewTos} className="text-flame hover:text-blue-600 cursor-pointer ml-1">terms of service</span>
          </label>
        </div>
        {isEditable && (
          <button type="button" onClick={handleNextStep} className="mt-4 w-full py-2 rounded-lg bg-flame hover:bg-red-600 text-white font-medium">
            Next
          </button>
        )}
      </form>
    </>
    
  );
};

export default BasicInfoForm;