export type Writer = {
    id: number
    firstName: string;
    lastName: string;
    Pro: string;
    ipiNumber: string;
    isUserAccount?: boolean;
}

export interface WriterFormProps {
    formData: { 
        firstName: string;
        lastName: string;
        Pro: string;
        caeIpiNumber: string;
    };
    handleSubmit: React.FormEventHandler<HTMLFormElement>;
    handleInputChange: (field: string, value: string) => void;
    error: string;
    success: string;
    closeModal: () => void;
}

export interface WriterRowProps {
    writer: Writer;
    handleEdit: (writer: number) => void;
    openDeleteModal: (id: number) => void;
}

export const writerHeaders = [
    { name: "Name" },
    { name: "PRO Membership" },
    { name: "CAE/IPI#" },
    { name: "Actions" },
];

export const ProOptions = [
    { label: 'SOCAN', value:'101 - SOCAN' },
    { label: 'BMI', value:'21 - BMI' },
    { label: 'ASCAP', value:'10 - ASCAP' },
    { label: 'AACIMH', value:'226 - AACIMH' },
    { label: 'AAS', value:'253 - AAS' },
    { label: 'ABRAMUS', value:'201 - ABRAMUS' },
    { label: 'ACAM', value:'107 - ACAM' },
    { label: 'ACDAM', value:'103 - ACDAM' },
    { label: 'ACEMLA', value:'76 - ACEMLA' },
    { label: 'ACS', value:'260 - ACS' },
    { label: 'ACUM', value:'1 - ACUM' },
    { label: 'ADAGP', value:'148 - ADAGP' },
    { label: 'ADAF', value:'2 - ADDAF' },
    { label: 'AEI', value:'250 - AEI' },
    { label: 'AEPI', value:'3 - AEPI' },
    { label: 'AGADU', value:'4 - AGADU' },
    { label: 'AGAYC', value:'114 - AGAYC' },
    { label: 'AKKA LAA', value:'122 - AKKA LAA' },
    { label: 'AKM', value:'5 - AKM' },
    { label: 'ALBAUTOR', value:'127 - ALBAUTOR' },
    { label: 'ALCS', value:'54 - ALCS' },
    { label: 'AMAR', value:'30 - AMAR' },
    { label: 'AMCOS', value:'12 - AMCOS' },
    { label: 'AMRA', value:'17 - AMRA' },
    { label: 'AMUS', value:'273 - AMUS' },
    { label: 'APRA', value:'8 - APRA' },
    { label: 'APDAYC', value:'7 - APDAYC' },
    { label: 'ARGENTORES', value:'14 - ARGENTORES' },
    { label: 'ARMAUTHOR', value:'209 - ARMAUTHOR' },
    { label: 'ARS', value:'149 - ARS' },
    { label: 'ARTISJUS', value:'9 - ARTISJUS' },
    { label: 'ASDAC', value:'202 - ASDAC' },
    { label: 'ASDACS', value:'251 - ASDACS' },
    { label: 'ASSIM', value:'219 - ASSIM' },
    { label: 'ATN', value:'141 - ATN' },
    { label: 'AUSTRO MECHANA', value:'11 - AUSTRO MECHANA' },
    { label: 'AUTVIS', value:'231 - AUTVIS' },
    { label: 'AWA', value:'13 - AWA' },
    { label: 'AWGACS', value:'203 - AWGACS' },
    { label: 'BBDA', value:'45 - BBDA' },
    { label: 'BCDA', value:'47 - BCDA' },
    { label: 'BEELDRECHT', value:'150 - BEELDRECHT' },
    { label: 'BGDA', value:'18 - BGDA' },
    { label: 'BILD KUNST', value:'132 - BILD KUNST' },
    { label: 'BMDA', value:'19 - BMDA' },
    { label: 'BNDA', value:'125 - BNDA' },
    { label: 'BONO', value:'151 - BONO' },
    { label: 'BSCAP', value:'238 - BSCAP' },
    { label: 'BSDA', value:'25 - BSDA' },
    { label: 'BUBEDRA', value:'37 - BUBEDRA' },
    { label: 'BUCADA', value:'6 - BUCADA' },
    { label: 'BUMA', value:'23 - BUMA' },
    { label: 'BUMDA', value:'16 - BUMDA' },
    { label: 'BURIDA', value:'24 - BURIDA' },
    { label: 'BUS', value:'152 - BUS' },
    { label: 'BUTODRA', value:'130 - BUTODRA' },
    { label: 'CAPAC', value:'27 - CAPAC' },
    { label: 'CASH', value:'26 - CASH' },
    { label: 'CHA', value:'108 - CHA' },
    { label: 'CMRRA', value:'88 - CMRRA' },
    { label: 'COMPASS', value:'106 - COMPASS' },
    { label: 'COPY DAN BILLEDKUNST', value:'139 - COPY DAN BILLEDKUNST' },
    { label: 'COSCAP', value:'169 - COSCAP' },
    { label: 'COSGA', value:'123 - COSGA' },
    { label: 'COSOMA', value:'124 - COSOMA' },
    { label: 'COSON', value:'268 - COSON' },
    { label: 'COSOTA', value:'223 - COSOTA' },
    { label: 'COTT', value:'96 - COTT' },
    { label: 'CREAIMAGEN', value:'171 - CREAIMAGEN' },
    { label: 'CSCS', value:'212 - CSCS' },
    { label: 'DAC', value:'248 - DAC' },
    { label: 'DACS', value:'153 - DACS' },
    { label: 'DALRO', value:'142 - DALRO' },
    { label: 'DAMA', value:'240 - DAMA' },
    { label: 'DHFR', value:'271 - DHFR' },
    { label: 'DILIA', value:'31 - DILIA' },
    { label: 'DIRECTORS UK', value:'145 - DIRECTORS UK' },
    { label: 'DRCC', value:'213 - DRCC' },
    { label: 'EAU', value:'116 - EAU' },
    { label: 'ECAD', value:'308 - ECAD' },
    { label: 'ECCO', value:'214 - ECCO' },
    { label: 'FILMAUTOR', value:'147 - FILMAUTOR' },
    { label: 'FILMJUS', value:'174 - FILMJUS' },
    { label: 'FILSCAP', value:'32 - FILSCAP' },
    { label: 'GAI UZ', value:'261 - GAI UZ' },
    { label: 'GCA', value:'204 - GCA' },
    { label: 'GEMA', value:'35 - GEMA' },
    { label: 'HAA', value:'144 - HAA' },
    { label: 'HARRY FOX AGENCY', value:'34 - HARRY FOX AGENCY' },
    { label: 'HDS ZAMP', value:'111 - HDS ZAMP' },
    { label: 'HUNGART', value:'154 - HUNGART' },
    { label: 'ICE', value:'319 - ICE' },
    { label: 'IMRO', value:'128 - IMRO' },
    { label: 'IPRS', value:'36 - IPRS' },
    { label: 'JACAP', value:'176 - JACAP' },
    { label: 'JASPAR', value:'270 - JASPAR' },
    { label: 'JASRAC', value:'38 - JASRAC' },
    { label: 'KAZAK', value:'177 - KAZAK' },
    { label: 'KCI', value:'109 - KCI' },
    { label: 'KODA', value:'40 - KODA' },
    { label: 'KOMCA', value:'118 - KOMCA' },
    { label: 'KOPIOSTO', value:'138 - KOPIOSTO' },
    { label: 'KUVASTO', value:'179 - KUVASTO' },
    { label: 'KYRGYZPATENT', value:'215 - KYRGYZPATENT' },
    { label: 'LAA', value:'113 - LAA' },
    { label: 'LATGA A', value:'110 - LATGA A' },
    { label: 'LIRA', value:'120 - LIRA' },
    { label: 'LITA', value:'28 - LITA' },
    { label: 'LITERAR MECHANA', value:'41 - LITERAR MECHANA' },
    { label: 'LVG', value:'42 - LVG' },
    { label: 'MACA', value:'265 - MACA' },
    { label: 'MACP', value:'104 - MACP' },
    { label: 'MASA', value:'105 - MASA' },
    { label: 'MCPS', value:'44 - MCPS' },
    { label: 'MCSC', value:'119 - MCSC' },
    { label: 'MCSK', value:'43 - MCSK' },
    { label: 'MCSN', value:'22 - MCSN' },
    { label: 'MCT', value:'126 - MCT' },
    { label: 'MESAM', value:'117 - MESAM' },
    { label: 'MRCSN', value:'258 - MRCSN' },
    { label: 'MRS', value:'46 - MRS' },
    { label: 'MSG', value:'200 - MSG' },
    { label: 'MUSICAUTOR', value:'39 - MUSICAUTOR' },
    { label: 'MUSIKEDITION', value:'180 - MUSIKEDITION' },
    { label: 'MUST', value:'161 - MUST' },
    { label: 'NASCAM', value:'102 - NASCAM' },
    { label: 'NCB', value:'48 - NCB' },
    { label: 'NCIP', value:'160 - NCIP' },
    { label: 'NS', value:'99 - NS' },
    { label: 'OMDA', value:'33 - OMDA' },
    { label: 'ONDAC', value:'49 - ONDAC' },
    { label: 'OSA', value:'50 - OSA' },
    { label: 'OTPDA', value:'82 - OTPDA' },
    { label: 'PAM CG', value:'249 - PAM CG' },
    { label: 'PICTORIGHT', value:'256 - PICTORIGHT' },
    { label: 'PROCAN', value:'53 - PROCAN' },
    { label: 'PROLITTERIS', value:'51 - PROLITTERIS' },
    { label: 'PRS', value:'52 - PRS' },
    { label: 'RAO', value:'94 - RAO' },
    { label: 'SABAM', value:'55 - SABAM' },
    { label: 'SACD', value:'56 - SACD' },
    { label: 'SACEM', value:'58 - SACEM' },
    { label: 'SACENC', value:'235 - SACENC' },
    { label: 'SACERAU', value:'57 - SACERAU' },
    { label: 'SACIM', value:'242 - SACIM' },
    { label: 'SACK', value:'183 - SACK' },
    { label: 'SACM', value:'59 - SACM' },
    { label: 'SACVEN', value:'60 - SACVEN' },
    { label: 'SADA', value:'131 - SADA' },
    { label: 'SADAIC', value:'61 - SADAIC' },
    { label: 'SADEMBRA', value:'62 - SADEMBRA' },
    { label: 'SADH', value:'135 - SADH' },
    { label: 'SAIF', value:'225 - SAIF' },
    { label: 'SAMRO', value:'63 - SAMRO' },
    { label: 'SARRAL', value:'81 - SARRAL' },
    { label: 'SASUR', value:'244 - SASUR' },
    { label: 'SAVA', value:'257 - SAVA' },
    { label: 'SAYCE', value:'65 - SAYCE' },
    { label: 'SAYCO', value:'84 - SAYCO' },
    { label: 'SAZAS', value:'112 - SAZAS' },
    { label: 'SBACEM', value:'66 - SBACEM' },
    { label: 'SBAT', value:'67 - SBAT' },
    { label: 'SCAM', value:'73 - SCAM' },
    { label: 'SCD', value:'29 - SCD' },
    { label: 'SDCSI', value:'259 - SDCSI' },
    { label: 'SDRM', value:'68 - SDRM' },
    { label: 'SESAC', value:'71 - SESAC' },
    { label: 'SGACEDOM', value:'227 - SGACEDOM' },
    { label: 'SGAE', value:'72 - SGAE' },
    { label: 'SIAE', value:'74 - SIAE' },
    { label: 'SICAM', value:'86 - SICAM' },
    { label: 'SLPRS', value:'134 - SLPRS' },
    { label: 'SOBODAYCOM', value:'129 - SOBODAYCOM' },
    { label: 'SOCINADA', value:'92 - SOCINADA' },
    { label: 'SOCINPRO', value:'189 - SOCINPRO' },
    { label: 'SODRAC', value:'20 - SODRAC' },
    { label: 'SOFAM', value:'137 - SOFAM' },
    { label: 'SOGEM', value:'70 - SOGEM' },
    { label: 'SOKOJ', value:'64 - SOKOJ' },
    { label: 'SOMAAP', value:'155 - SOMAAP' },
    { label: 'SONECA', value:'83 - SONECA' },
    { label: 'SOZA', value:'85 - SOZA' },
    { label: 'SPA', value:'69 - SPA' },
    { label: 'SPAC', value:'146 - SPAC' },
    { label: 'SPACEM', value:'87 - SPACEM' },
    { label: 'SQN', value:'216 - SQN' },
    { label: 'SSA', value:'91 - SSA' },
    { label: 'STEF', value:'77 - STEF' },
    { label: 'STEMRA', value:'78 - STEMRA' },
    { label: 'STIM', value:'79 - STIM' },
    { label: 'SUISA', value:'80 - SUISA' },
    { label: 'SUISSIMAGE', value:'75 - SUISSIMAGE' },
    { label: 'TALI', value:'237 - TALI' },
    { label: 'TEATERAUTOR', value:'143 - TEATERAUTOR' },
    { label: 'TEOSTO', value:'89 - TEOSTO' },
    { label: 'TONO', value:'90 - TONO' },
    { label: 'UACRR', value:'140 - UACRR' },
    { label: 'UBC', value:'93 - UBC' },
    { label: 'UCMR ADA', value:'115 - UCMR ADA' },
    { label: 'UPRS', value:'234 - UPRS' },
    { label: 'VAGA', value:'156 - VAGA' },
    { label: 'VBK', value:'157 - VBK' },
    { label: 'VCPMC', value:'246 - VCPMC' },
    { label: 'VDFS', value:'121 - VDFS' },
    { label: 'VEGAP', value:'158 - VEGAP' },
    { label: 'VEVAM', value:'195 - VEVAM' },
    { label: 'VG WORT', value:'95 - VG WORT' },
    { label: 'VISCOPY', value:'159 - VISCOPY' },
    { label: 'WAMI', value:'269 - WAMI' },
    { label: 'ZAIKS', value:'97 - ZAIKS' },
    { label: 'ZAMCOPS', value:'133 - ZAMCOPS' },
    { label: 'ZAMP', value:'136 - ZAMP' },
    { label: 'ZAPA', value:'199 - ZAPA' },
    { label: 'ZIMURA', value:'98 - ZIMURA' },
];