import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../global/storage/userContext';
import { Eye, EyeOff } from 'lucide-react';
import { LogoBanner } from '../../global/ui/banners';

const LoginPage: React.FC = () => {
  const { login } = useUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
};

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await login(email, password);
      navigate('/');
    } catch (error: any) {
      setLoginError(error.message);
    }
  };
  
  return (
    <div className="min-h-screen bg-black flex items-center justify-center p-6">
      <div className="w-full max-w-md">
        <div className="bg-DarkNight p-8 rounded-lg shadow-md">
          <h1 className="text-2xl font-bold mb-2 text-center text-honeydew">Sign in to your account</h1>
          <div className="text-honeydew text-sm mb-8 text-center">Don't have an account? <a href="/signup" className="text-flame hover:text-blue-600">Sign up</a></div>
          <form onSubmit={handleLogin} className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-honeydew">Email</label>
              <input type="email" id="email" value={email} onChange={(e) => {setEmail(e.target.value); setLoginError('');}} className="w-full px-3 py-2 border rounded-md" placeholder="email" autoComplete="off" required />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-honeydew">Password</label>
              <div className="relative">
                <input type={showPassword ? "text" : "password"} id="password" value={password} onChange={(e) => {setPassword(e.target.value); setLoginError('');}} className="w-full px-3 py-2 border rounded-md" placeholder="password" autoComplete="off" required />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  {showPassword ? (<EyeOff className="cursor-pointer" onClick={togglePasswordVisibility} />) : (<Eye className="cursor-pointer" onClick={togglePasswordVisibility} />)}
                </div>
              </div>
            </div>
            <a href="/forgot-password" className="text-sm text-flame hover:text-blue-600 float-right mt-2">Forgot your password?</a>
            {loginError && <div className="text-white text-sm pt-5 text-center">{loginError}</div>}
            <button type="submit" className="w-full py-2 rounded-md bg-flame hover:bg-honeydew text-white hover:text-flame font-medium">Sign in</button>
          </form>
          <LogoBanner />
        </div>
      </div>
    </div>
  );
};  

export default LoginPage;
  