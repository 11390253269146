import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify } from '@fortawesome/free-brands-svg-icons';
import { Search, Info, Play, Pause } from 'lucide-react';
import CustomDropdown from "../../../../global/ui/customDropdown";
import { RoleOptions, initialSongFormData } from '../utlis/types/songTypes';
import { Writer } from '../utlis/types/wrtiterTypes';
import { 
    fetchWritersDropdown, handleSplit, handleInputChange, handleSubmit, 
    handleAddWriter, handleRemoveWriter, handleRoleChange, handleRTCChange, 
    handleSplitChange, handleEvenSplit, fetchSongDetails,
    fetchSpotifySearch,
} from '../utlis/services/songpageFunctions';
import { InfoBanner } from "../../../../global/ui/banners";
import { GeneralButton } from "../../../../global/ui/buttons";
import { SectionContainer } from "../../../../global/ui/containers";

const SongFormScreen: React.FC = () => {
    const navigate = useNavigate();
    const { songId } = useParams<{ songId?: string }>();
    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<string>("");
    const [formData, setFormData] = useState(initialSongFormData);
    const [userWriters, setUserWriters] = useState<Writer[]>([]);
    const [selectedSongId, setSelectedSongId] = useState<string | null>(null);
    const [selectedWriter, setSelectedWriter] = useState<string>('');
    const [editing, setEditing] = useState<boolean>(false);
    const [showSpotifySearch, setShowSpotifySearch] = useState<boolean>(false);
    const [spotifyTrackUrl, setSpotifyTrackUrl] = useState<string>('');
    const [showSpotifyGuide, setShowSpotifyGuide] = useState<boolean>(false);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [albumArtUrl, setAlbumArtUrl] = useState<string | null>(null);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [duration, setDuration] = useState<number>(0);

    const writerOptions = Array.isArray(userWriters) && userWriters.length > 0 ? userWriters
            .filter(writer => !formData.WorkWriters.some(existingWriter => existingWriter.Writer.id === writer.id))
            .map(writer => ({ label: `${writer.firstName} ${writer.lastName}`, value: `${writer.firstName} ${writer.lastName}` })) 
        : [];
    
    useEffect(() => {
        let debounceCheck: NodeJS.Timeout | undefined;
        clearTimeout(debounceCheck);
        debounceCheck = setTimeout(() => {
            if (songId) {
                setEditing(true);
                setSelectedSongId(songId);
                fetchSongDetails(songId, setError).then(data => {
                    if (data && data.WorkWriters) {
                        let artist = data.performingArtist;
                        const transformedData = {...data,
                            writers: data.WorkWriters.map((writer:any) => ({
                                id: writer.Writer.id,
                                artist: artist,
                                firstName: writer.Writer.firstName,
                                lastName: writer.Writer.lastName,
                                role: writer.role,
                                split: writer.split,
                                rtc: writer.rtc,
                            }))
                        };
                        setFormData(transformedData);
                    } 
                }).catch(err => {
                    console.error('Error fetching song data:', err);
                });
            }
        }, 500);
        return () => clearTimeout(debounceCheck);
    }, [songId]);

    useEffect(() => {
        let debounceCheck: NodeJS.Timeout | undefined;
        clearTimeout(debounceCheck);
        debounceCheck = setTimeout(() => {
            fetchWritersDropdown(setUserWriters);
        }, 500);
        return () => clearTimeout(debounceCheck);
    }, []);

    useEffect(() => {
        handleSplit(formData.WorkWriters, setError);
    }, [formData.WorkWriters]);

    const onSubmit = (e: React.FormEvent) => {
        handleSubmit({e, formData, setError, setSuccess, editing, selectedSongId, navigate});
    };

    const handleStandardInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setError("");
        setSuccess("");
        handleInputChange(e.target.name, e.target.value, setFormData);
    };

    const addWriter = () => {
        handleAddWriter({ selectedWriter, userWriters, formDataWriters: formData.WorkWriters, setFormData, setUserWriters,setSelectedWriter });
    };

    const deleteWriter = (index: number) => {
        handleRemoveWriter({ index, formDataWriters: formData.WorkWriters, setFormData, userWriters, setUserWriters });
    };

    const changeWriterRole = (index: number, newRole: string) => {
        handleRoleChange({ index, newRole, formDataWriters: formData.WorkWriters,setFormData });
    };

    const changeWriterRTC = (index: number, rtc: boolean) => {
        handleRTCChange({ index, rtc, formDataWriters: formData.WorkWriters, setFormData, });
    };
    
    
    const changeWriterSplit = (index: number, value: string) => {
        const newSplit = value === '' ? '' : Math.min(Math.max(0, Number(value)), 100);
        handleSplitChange({ index, newSplit: newSplit as number | '', formDataWriters: formData.WorkWriters, setFormData });
    };

    const toggleSpotifySearch = () => {
        setShowSpotifySearch(!showSpotifySearch);
    };

    const handleSpotifyFetch = () => {
        if (spotifyTrackUrl === '') {
            setError('Please enter a Spotify track URL');
            return;
        }
        
        // Extract track ID from URL
        const trackId = spotifyTrackUrl.split('/').pop()?.split('?')[0];
        
        if (!trackId) {
            setError('Invalid Spotify track URL');
            return;
        }

        fetchSpotifySearch(trackId, setFormData, setError, setPreviewUrl, setAlbumArtUrl);
        setError('');
        setSuccess('');
    };

    const togglePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleTimeUpdate = () => {
        if (audioRef.current) {
            setCurrentTime(audioRef.current.currentTime);
        }
    };

    const handleLoadedMetadata = () => {
        if (audioRef.current) {
            setDuration(audioRef.current.duration);
        }
    };

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const toggleSpotifyGuide = () => {
        setShowSpotifyGuide(!showSpotifyGuide);
    };

    const gotoSong = () => {
        navigate('/songs');
    }

    return (
        <SectionContainer >
            <div className=" flex flex-row justify-between mb-10">
                <button onClick={gotoSong}  className="p-2 text-flame hover:text-lightFlame hover:underline font-bold">&#8592; Back</button>
            </div>
            <form onSubmit={onSubmit} className="space-y-6">
                <InfoBanner message={success} />
                <InfoBanner message={error} />
                <div className="flex flex-col space-y-4">
                    {showSpotifySearch && (
                        <div className="flex flex-col space-y-2">
                            <div className="flex flex-col md:flex-row md:items-center">
                                <label htmlFor="spotifyTrackUrl" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0">Spotify Track URL</label>
                                <div className="flex w-full md:w-3/4">
                                    <input id="spotifyTrackUrl" type="text" placeholder="Enter Spotify track URL" 
                                        value={spotifyTrackUrl} onChange={(e) => setSpotifyTrackUrl(e.target.value)} 
                                        className="flex-grow border border-flame rounded-l-md bg-gray-200 dark:bg-Night p-2 px-4"/>
                                    <button type="button" onClick={handleSpotifyFetch} className="bg-flame hover:bg-lightFlame text-white p-2 rounded-r-lg">
                                        <Search />
                                    </button>
                                </div>
                            </div>
                            <div className="flex items-center text-sm text-black dark:text-gray-400">
                                <button type="button" onClick={toggleSpotifyGuide} className="flex items-center hover:text-flame">
                                    <Info size={16} className="mr-1" />
                                    How to get the Spotify URL
                                </button>
                            </div>
                            {showSpotifyGuide && (
                                <div className="bg-gray-200 dark:bg-Night p-4 rounded-md text-sm">
                                    <h4 className="font-bold mb-2">How to get the Spotify Track URL:</h4>
                                    <ol className="list-decimal list-inside space-y-1">
                                        <li>Open Spotify and find the track you want.</li>
                                        <li>Click on the three dots (...) next to the track name.</li>
                                        <li>Go to "Share" and select "Copy link to song".</li>
                                        <li>Paste the copied URL into the input field below.</li>
                                    </ol>
                                    <p className="mt-2">The URL should look like: https://open.spotify.com/track/1234567890abcdef</p>
                                </div>
                            )}
                            {previewUrl && (
                                <div className="mt-4 bg-gray-200 dark:bg-Night rounded-lg p-4 w-full lg:w-3/4 lg:ml-60">
                                    <div className="flex items-center space-x-4">
                                        <img src={albumArtUrl || 'path/to/default/image.jpg'} alt="Album Art" className="w-16 h-16 rounded-md"/>
                                        <div className="flex-grow">
                                            <div className="flex items-center justify-between mb-2">
                                                <button type="button" onClick={togglePlayPause} className="bg-flame hover:bg-lightFlame text-white p-2 rounded-full">
                                                    {isPlaying ? <Pause size={20} /> : <Play size={20} />}
                                                </button>
                                                <h2>{formData.title} - {formData.performingArtist}</h2>
                                                <div className="text-sm text-black dark:text-gray-400">
                                                    {formatTime(currentTime)} / {formatTime(duration)}
                                                </div>
                                            </div>
                                            <div className="w-full bg-gray-500 dark:bg-gray-700 rounded-full h-1">
                                                <div 
                                                    className="bg-flame h-1 rounded-full" 
                                                    style={{width: `${(currentTime / duration) * 100}%`}}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                    <audio ref={audioRef} src={previewUrl} onTimeUpdate={handleTimeUpdate} onLoadedMetadata={handleLoadedMetadata} onEnded={() => setIsPlaying(false)}/>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                    <div className="flex flex-col md:flex-row md:items-center w-full">
                        <label htmlFor="title" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0">Title<span className="text-red-500 text-base ml-1">*</span></label>
                        <input type="text" id="title" name="title" className="border border-flame rounded-md bg-gray-200 dark:bg-Night p-2 px-4 w-full md:w-3/4" value={formData.title} onChange={handleStandardInputChange} placeholder="Song Title or Use the Import from Spotify button" autoComplete="off"/>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row md:items-center">
                    <label htmlFor="performingArtist" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0">Performing Artist(s)<span className="text-red-500 text-base ml-1">*</span></label>
                    <input type="text" id="performingArtist" name="performingArtist" placeholder="John smith, Jane smith" className="border border-flame rounded-md bg-gray-200 dark:bg-Night p-2 px-4 w-full md:w-3/4" value={formData.performingArtist} onChange={handleStandardInputChange} autoComplete="off"/>
                </div>
                <div className="flex flex-col md:flex-row md:items-center">
                    <label htmlFor="duration" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0">Duration (HH:MM:SS)</label>
                    <input type="text" id="duration" name="duration" placeholder="00:00:00" pattern="\d{2}:\d{2}:\d{2}" className="border border-flame rounded-md bg-gray-200 dark:bg-Night p-2 px-4 w-full md:w-3/4" value={formData.duration} onChange={handleStandardInputChange} autoComplete="off"/>
                </div>
                <div className="flex justify-end">
                    <button type="button" onClick={toggleSpotifySearch} className="flex items-center bg-black dark:bg-white text-white dark:text-black px-4 py-2 rounded-full hover:bg-[#1ed760] hover:text-black dark:hover:bg-[#1ed760] transition-colors duration-300">
                        <FontAwesomeIcon icon={faSpotify} className="mr-2" />
                        Import from Spotify
                    </button>
                </div>

                <div>
                    <div className="px-4 py-3 md:py-5 rounded-t-lg bg-gray-200 dark:bg-Night md:text-xl">
                        <div className="flex justify-between items-center mb-2">
                            <div>
                                Writer splits assigned: {formData.WorkWriters.reduce((acc, curr) => acc + Number(curr.split), 0)}%
                            </div>
                            <button type="button" className="text-flame hover:text-lightFlame text-sm" onClick={() => handleEvenSplit(formData, setFormData)}>
                                Split Shares Evenly
                            </button>
                        </div>
                        <div className="w-2/4 bg-white rounded-lg overflow-hidden">
                            <div style={{ width: `${formData.WorkWriters.reduce((acc, curr) => acc + Number(curr.split), 0)}%` }} className="h-4 bg-flame progress-bar rounded-r-md"></div>
                        </div>
                    </div>
                    <div className="bg-gray-400 dark:bg-DarkNight rounded-md">
                        <div className="uppercase bg-gray-200 dark:bg-Night text-black dark:text-white text-sm underline px-6 py-4 hidden lg:flex">
                            <div id="colWriterSplitName" className="w-2/12"><p>Writer name</p></div>
                            <div id="colWriterSplitRole" className="w-2/12"><p>Role</p></div>
                            <div id="colWriterSplitSplit" className="w-5/12"><p>Split</p></div>
                            <div id="colWriterSplitSplit" className="w-3/12 text-right"><p>Action</p></div>
                        </div>
                        {formData.WorkWriters.map((writer, index) => (
                            <>
                                <div key={writer.writerId} className="flex flex-wrap items-center p-4">
                                    <div className="w-full lg:w-2/12 mb-2 lg:mb-0"><h2 className="text-sm">{writer.Writer.firstName} {writer.Writer.lastName}</h2></div>

                                    <CustomDropdown value={writer.role} onChange={(newRole) => changeWriterRole(index, newRole)} options={RoleOptions} placeholder="Select a Role" id={`role_${index}`} />

                                    <input id={`split_${index}`} type="text" inputMode="numeric" pattern="[0-9]*" className="border border-flame bg-gray-200 dark:bg-Night rounded-md w-full lg:w-1/6 p-2 mb-2 mt-2 lg:mt-0 lg:ml-10 lg:mb-0" value={writer.split === 0 ? '' : writer.split} onChange={(e) => changeWriterSplit(index, e.target.value)} autoComplete="off"/>
                                    
                                    <div className="flex items-center w-full lg:w-2/12 mt-2 lg:ml-5">
                                        <input type="checkbox" id={`righttocollect__${index}`} checked={writer.rtc} name={`righttocollect__${index}`} readOnly={true}  />
                                        <label htmlFor={`righttocollect__${index}`} className="ml-2 text-xs font-semibold">I have the right to collect royalties for this writer</label>
                                    </div>

                                    <button className="text-red-500 text-2xl flex-1 text-right" onClick={() => deleteWriter(index)}>&times;</button>
                                </div>
                                <div className="px-6"><div className="w-full border-b border-honeydew"></div></div>
                            </>
                        ))}
                        <div className='px-4 py-2 flex justify-between items-center'>
                            <CustomDropdown value={selectedWriter} onChange={setSelectedWriter} options={writerOptions} placeholder="Select a Writer" id="Writer"/>
                            <button type="button" onClick={addWriter} className="text-flame hover:text-lightFlame hover:underline font-bold text-left ml-5">Add Writer</button>
                        </div>
                    </div>
                </div>

                <div className="flex justify-end items-center">
                    {editing === true && <GeneralButton type="button" text="Cancel" onClick={gotoSong} space={true} />}
                    <GeneralButton text="Submit" type="submit" />
                </div>
            </form>
        </SectionContainer>
    );
};

export default SongFormScreen;

