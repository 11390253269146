import { User } from "../types/exportTypes";

export const fetchUserDropdown = async (setUserDropdown: (writers: User[]) => void) => {
    try {
        const response = await fetch('/api/user/users');
        const data = await response.json();
        setUserDropdown(data.users);
    } catch (error) {
        console.error('Error fetching writers:', error);
    }
};

export const exportSongDetails = async (format: string, fromDate: string, toDate: string, fileName: string, setError: React.Dispatch<React.SetStateAction<string>>, selectedUser: string) => {
    try {
        const response = await fetch('/api/songs/export', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                format,
                fromDate,
                toDate,
                fileName,
                selectedUser
            }),
        });

        if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${fileName}_all_exports.zip`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } else {
            const errorData = await response.json();
            setError(errorData.message || 'An error occurred during export');
        }
    } catch (error) {
        console.error('Error during export:', error);
        setError('An unexpected error occurred');
    }
};