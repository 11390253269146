import React from 'react';
import { WriterRowProps } from '../utlis/types/wrtiterTypes';
import { Pencil, Trash2 } from 'lucide-react';

const WriterDataTable: React.FC<WriterRowProps> = ({ writer, handleEdit, openDeleteModal }) => {
    return (
        <tr className="hover:bg-gray-200 dark:hover:bg-Night">
            <td className="border-t px-4 py-2 max-w-xs whitespace-normal overflow-hidden rounded-l-md">{writer.firstName} {writer.lastName} {writer.isUserAccount ? <span className="text-sm text-gray-500"> (Me)</span> : null}</td>
            <td className="border-t px-4 py-2 max-w-xs whitespace-normal overflow-hidden">{writer.Pro || '-'}</td>
            <td className="border-t px-4 py-2 max-w-xs whitespace-nowrap">{writer.ipiNumber || '-'}</td>
            <td className="border-t px-4 py-2 text-right whitespace-nowrap rounded-r-md">
                <button onClick={() => handleEdit(writer.id)} className="dark:text-white hover:text-lightFlame">
                    <Pencil />
                </button>
                {writer.isUserAccount === false ? (
                    <button onClick={() => openDeleteModal(writer.id)} className="ml-3 dark:text-white hover:text-lightFlame">
                        <Trash2 />
                    </button>
                ) : null}
            </td>
        </tr>
    );
}

export default WriterDataTable;
