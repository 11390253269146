import React, { useEffect, useState } from 'react';

const CountdownTimer: React.FC<{ duration: number, onComplete: () => void }> = ({ duration, onComplete }) => {
    const [remainingTime, setRemainingTime] = useState(duration);
    const circumference = 2 * Math.PI * 45; // 45 is the radius of our circle
  
    useEffect(() => {
      if (remainingTime > 0) {
        const timer = setTimeout(() => setRemainingTime(prev => prev - 10), 10);
        return () => clearTimeout(timer);
      } else {
        onComplete();
      }
    }, [remainingTime, onComplete]);
  
    const strokeDashoffset = circumference * (1 - remainingTime / duration);
  
    return (
      <div className="relative w-20 h-20 mx-auto mt-4">
        <svg className="w-full h-full" viewBox="0 0 100 100">
          <circle
            className="text-gray-300"
            strokeWidth="5"
            stroke="currentColor"
            fill="transparent"
            r="45"
            cx="50"
            cy="50"
          />
          <circle
            className="text-flame"
            strokeWidth="5"
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
            strokeLinecap="round"
            stroke="currentColor"
            fill="transparent"
            r="45"
            cx="50"
            cy="50"
          />
        </svg>
        <span className="absolute inset-0 flex items-center justify-center text-honeydew">
          {Math.ceil(remainingTime / 1000)}
        </span>
      </div>
    );
};

export default CountdownTimer;