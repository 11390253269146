import React from "react";
import UnverifiedDashboard from "./unVerifiedDashboard";
import { useUser } from "../../../../global/storage/userContext";
import VerifiedDashboard from "./verifiedDashboard";

const DashboardScreen: React.FC = () => {
    const { user } = useUser();
    return (
        <>
            {user?.verified ? (
                <VerifiedDashboard />
            ) : (
                <UnverifiedDashboard />
            )}
        </>
    );
};
  
export default DashboardScreen;  