import React from "react";
import { useUser } from "../../../../global/storage/userContext";
import { Lock, ServerCog } from 'lucide-react';
import { APP_VERSION } from "../../../../../config";
import SecuritySection from "./components/securitySection";
import OthersSection from "./components/othersSection";
import DisplaySection from "./components/displaySection";
import SubSettingsContainer from "./components/subSettingsContainer";
import { SectionContainer } from "../../../../global/ui/containers";

const SettingsScreen: React.FC = () => {
    const { user } = useUser();

    return (
        <SectionContainer title="Settings">
            <div className="mt-3 text-lg font-semibold">
                <h2 className="capitalize"> {user?.artistName}</h2>
                <h2 className="capitalize"> {user?.firstName} {user?.lastName}</h2>
            </div>
            <div className="h-3"></div>
            <SubSettingsContainer title="Security" icon={Lock}>
                <SecuritySection />
            </SubSettingsContainer>
            <SubSettingsContainer title="Display" icon={Lock}>
                <DisplaySection />
            </SubSettingsContainer>
            {user?.verified && user?.auth === 'user' && (
                <SubSettingsContainer title="Others" icon={ServerCog}>
                    <OthersSection />
                </SubSettingsContainer>
            )}
                <div className="text-sm font-semibold">Version: {APP_VERSION}</div>
        </SectionContainer>
    );
};

export default SettingsScreen;
