import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../../global/storage/userContext';
import CountdownTimer from '../../global/ui/countdownTimer';
import { LogoBanner } from '../../global/ui/banners';

const EmailVerificationPage: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [message, setMessage] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useUser();
  const verificationAttempted = useRef(false);

  useEffect(() => {
    const verifyEmail = async () => {
      if (verificationAttempted.current) return;
      verificationAttempted.current = true;

      try {
        const response = await fetch(`/api/user/verify-email/${token}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });
        const data = await response.json();
        if (response.ok) {
          setMessage(data.message);
          setUser(data.user);
          setIsVerified(true);
        } else {
          setMessage(data.error || 'An error occurred during email verification.');
        }
      } catch (error) {
        console.error('Error during email verification:', error);
        setMessage('An error occurred during email verification.');
      }
    };

    verifyEmail();
  }, [token, setUser]);

  return (
    <div className="min-h-screen bg-black flex items-center justify-center p-6">
      <div className="w-full max-w-md bg-DarkNight p-8 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4 text-center text-honeydew">Email Verification</h1>
        <p className="text-honeydew text-center mb-4">{message}</p>
        {isVerified && (
          <>
            <p className="text-honeydew text-center mb-2">Redirecting to dashboard in:</p>
            <CountdownTimer duration={3000} onComplete={() => navigate('/')} />
          </>
        )}
      </div>
      <LogoBanner />
    </div>
  );
};

export default EmailVerificationPage;