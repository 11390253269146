interface Header {
    name: string;
}
   
interface DynamicTableProps {
    data: any[];
    headers: Header[];
    renderRow: (item: any, index: number) => JSX.Element;
    loading: boolean;
}

const DynamicTable: React.FC<DynamicTableProps> = ({ data, headers, renderRow, loading }) => {
    return (
      <table className="w-full table-auto">
        <thead>
          <tr className="text-left">
            {headers.map((header, index) => (
              <th key={index} className={`px-4 py-2 ${['Actions', 'Data Added'].includes(header.name) ? 'text-end' : ''}`}>{header.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={headers.length} className="text-center text-base py-10">
                Loading
                <div className="inline-block animate-bounce animation-delay-200 text-white">.</div>
                <div className="inline-block animate-bounce animation-delay-400 text-flame">.</div>
                <div className="inline-block animate-bounce animation-delay-600 text-white">.</div>
              </td>
            </tr>
          ) : data.length > 0 ? (
            data.map(renderRow)
          ) : (
            <tr>
              <td colSpan={headers.length} className="text-center py-10">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    );
};

export default DynamicTable;