import React from 'react';
import { Writer, WriterFormProps } from '../types/wrtiterTypes';

export const fetchWriters = async (page: number, pageSize:number, searchQuery:string, setLoading: (newData: any) => void, setTotalPages: (newData: any) => void, setWritersData: (newData: any) => void) => {
    setLoading(true);
    try {
        const response = await fetch(`/api/writers/users-writers?page=${page}&pageSize=${pageSize}&search=${encodeURIComponent(searchQuery)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setWritersData(data.writers);
        setTotalPages(data.totalPages);
    } catch (error) {
        console.error('Error fetching writers:', error);
    }
    setLoading(false);
};

export const deleteWriter = async (selectedWriterId: number) => {
    try {
        const response = await fetch(`/api/writers/delete/${selectedWriterId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        return response.ok;
    } catch (error) {
        console.error('Error deleting writer:', error);
    }
}

export const fetchWritersDropdown = async (setUserWriters: (writers: Writer[]) => void) => {
    try {
        const response = await fetch('/api/writers/writers-dropdown');
        const data = await response.json();
        setUserWriters(data.writers);
    } catch (error) {
        console.error('Error fetching writers:', error);
    }
};

export const formSubmit = async (formData: WriterFormProps['formData'], setError: (newData: string) => void, endpoint: string, method:string) => {
    try {
        const response = await fetch(endpoint, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(formData),
        });

        return response.ok;

    } catch(error: any) {
        setError("An error occurred while saving the writer: " + error.message);
    }
}