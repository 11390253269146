import React, { ReactNode } from 'react';
import { LucideIcon } from 'lucide-react';

interface SubSettingsContainerProps {
  title?: string;
  icon?: LucideIcon;
  children?: ReactNode;
}

const SubSettingsContainer: React.FC<SubSettingsContainerProps> = ({ title, icon: Icon, children }) => {
  return (
    <div className="mt-3 text-md font-semibold mb-5">
      <div className="space-y-4">
        <h2 className="flex items-center">
          {Icon && <Icon className="mr-2 mb-1" />}
          {title}
        </h2>
        <div className="container mx-auto bg-gray-300 dark:bg-black rounded-lg shadow overflow-hidden p-6">
          {children}
        </div>
      </div>
    </div>
  );
};

export default SubSettingsContainer;
