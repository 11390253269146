import React, { useState } from 'react';
import CustomDropdown from '../../../global/ui/customDropdown';
import { ProOptions } from '../../main/components/utlis/types/wrtiterTypes';

interface SocietyStepProps {
  userId: number;
  handleNextStep: () => void;
  onSkip: () => void;
}

const PreviousPublisherStep: React.FC<SocietyStepProps> = ({ userId, handleNextStep, onSkip }) => {
  const [hasPublisher, setHasPublisher] = useState<string>('');
  const [publisherEmail, setPublisherEmail] = useState<string>('');
  const [publisherName, setPublisherName] = useState<string>('');

  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);



  const handleSubmit = async () => {
    setIsLoading(true);
    setMessage('');
    handleNextStep();
  };

  return (
    <div className="space-y-4">
      <p className="text-sm text-honeydew mb-4 mt-5">Are you already registered with a publisher?</p>
      <div className="flex items-center justify-center space-x-4">
        <button onClick={() => {setHasPublisher('yes'); setMessage('')}} className={`px-4 py-2 rounded ${hasPublisher === 'yes' ? 'bg-flame text-white' : 'bg-gray-200 text-black'}`}>
          Yes
        </button>
        <button onClick={() => {setHasPublisher('no'); setMessage('')}} className={`px-4 py-2 rounded ${hasPublisher === 'no' ? 'bg-flame text-white' : 'bg-gray-200 text-black'}`}>
          No
        </button>
      </div>

      {hasPublisher === 'yes' && (
        <div className="space-y-4 text-honeydew">
            <p>
                As your previous publisher may have registered some of your muisc under their own name, we cant register that same songs on our app. if you want us to 
                procced with registering thoses songs in out app you have to contact your previous publisher and let them know that you want to register your music on thefidia.com.
            </p>
          <button onClick={handleSubmit} disabled={isLoading} className="w-full px-4 py-2 bg-flame text-white rounded disabled:opacity-50">
            {isLoading ? 'Updating...' : 'Continue'}
          </button>
        </div>
      )}

      {hasPublisher === 'no' && (
        <div>
          <p className="text-sm text-honeydew mb-4">All set! You can now continue to the next step.</p>
          <button onClick={onSkip} className="w-full px-4 py-2 bg-flame text-white rounded">
            Continue
          </button>
        </div>
      )}

      {message && <p className="text-sm text-red-500">{message}</p>}
    </div>
  );
};

export default PreviousPublisherStep;