import React, { useState } from 'react';
import CustomDropdown from '../../../../global/ui/customDropdown';
import { exportSongDetails } from '../utlis/services/exportpageFunctions';
import { InfoBanner } from '../../../../global/ui/banners';
import { GeneralButton } from '../../../../global/ui/buttons';

interface SongDetailDownloadModalProps {
    closeModal: () => void;
    selectedUser: string;
    selectedUserName?: string;
}

const SongDetailDownloadModal: React.FC<SongDetailDownloadModalProps> = ({  closeModal, selectedUser, selectedUserName }) => {
    const [fileName, setFileName] = useState(`${selectedUserName || 'username'}_${new Date().toLocaleDateString().replace(/\//g, '-')}`);
    
    const [format, setFormat] = useState('Excel');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [error, setError] = useState<string>("");

    const [isLoading, setIsLoading] = useState(false);

    const formats = [
        { label: 'Excel', value: 'Excel' }
    ];

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!fileName || !format || !fromDate || !toDate) {
            setError("Please fill out all required fields");
            return;
        } else {
            setIsLoading(true);
            try {
                await exportSongDetails(format, fromDate, toDate, fileName, setError, selectedUser);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            {error && <InfoBanner message={error} />}
            <div className="flex flex-col md:flex-row items-center">
                <label htmlFor="fileName" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0 md:ml-20 lg:ml-52">Save as:<span className="text-red-500 text-base ml-1">*</span></label>
                <input type="text" id="fileName" name="fileName" className="border border-flame rounded-md bg-gray-200 dark:bg-Night p-2 px-4 w-full md:w-2/5" value={fileName} onChange={(e) => {setError(""); setFileName(e.target.value)}} />
            </div>
            <div className="flex flex-col md:flex-row items-center">
                <label htmlFor="format" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0 md:ml-20 lg:ml-52">Format:<span className="text-red-500 text-base ml-1">*</span></label>
                <CustomDropdown id="format" options={formats} value={format} onChange={(value) => {setError(""); setFormat(value)}}/>
            </div>
            <div className="flex flex-col md:flex-row items-center">
                <label htmlFor="fromDate" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0 md:ml-20 lg:ml-52">From:<span className="text-red-500 text-base ml-1">*</span></label>
                <input type="date" id="fromDate" name="fromDate" className="border border-flame rounded-md bg-gray-200 dark:bg-Night p-2 px-4 w-full md:w-2/5" value={fromDate} onChange={(e) => {setError(""); setFromDate(e.target.value)}} />
            </div>
            <div className="flex flex-col md:flex-row items-center">
                <label htmlFor="toDate" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0 md:ml-20 lg:ml-52">To:<span className="text-red-500 text-base ml-1">*</span></label>
                <input type="date" id="toDate" name="toDate" className="border border-flame rounded-md bg-gray-200 dark:bg-Night p-2 px-4 w-full md:w-2/5" value={toDate} onChange={(e) => {setError(""); setToDate(e.target.value)}} />
            </div>
            <div className="flex justify-end items-center mb-10">
                <GeneralButton type="button" onClick={closeModal} text="Cancel" space={true} disabled={isLoading} />
                <GeneralButton type="submit" text={isLoading ? "Exporting..." : "Submit"} disabled={isLoading} />
                {isLoading && <div className="ml-2 animate-spin rounded-full h-6 w-6 border-b-2 border-flame"></div>}
            </div>
        </form>
    );
};

export default SongDetailDownloadModal;
