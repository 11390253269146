import React, { ReactNode } from "react";
import { useNavigate } from 'react-router-dom';

interface ErrorBoundaryProps {
    children: ReactNode;
    onReset?: () => void; 
}

interface ErrorBoundaryState {
    hasError: boolean;
    error: Error | null;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <ErrorFallback onReset={this.props.onReset} />
            );
        }

        return this.props.children;
    }
}

const ErrorFallback = ({ onReset }: { onReset?: () => void }) => {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/');
        if (onReset) {
            onReset();
        }
    };

    return (
        <div className="h-screen flex flex-col justify-center items-center bg-gray-100 text-center">
            <h1 className="text-xl font-semibold text-gray-800">Whoops, something went wrong...</h1>
            <p className="mb-4">We're working on it. Please try again later.</p>
            <button 
                onClick={handleGoHome} 
                className="px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-red-500 hover:bg-red-700"
            >
                Go Home
            </button>
        </div>
    );
};

export default ErrorBoundary;
