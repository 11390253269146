import React, { ReactNode } from 'react';

interface SubSectionContainerProps {
  title: string;
  children: React.ReactNode;
  rightElement?: React.ReactNode;
  border?: boolean;
}
interface SectionContainerProps {
    title?: string;
    children?: ReactNode;
    rightElement?: ReactNode;
  }

export const SectionContainer: React.FC<SectionContainerProps> = ({ title, children, rightElement }) => {
    return (
      <div className="min-h-screen p-10 mt-5 md:mt-0">
        <div className="container mx-auto bg-white dark:bg-eerieBlack text-DarkNight dark:text-honeydew rounded-lg shadow overflow-hidden">
          <div className="p-4 md:p-8">
            <div className="flex flex-row justify-between items-center mb-3">
              <div>
                <h1 className="text-xl md:text-2xl font-semibold inline-block">{title}</h1>
              </div>
              {rightElement}
            </div>
          {children}
        </div>
        <div className="h-5"></div>
      </div>
    </div>
  );
};

export const SubSectionContainer: React.FC<SubSectionContainerProps> = ({ title, children, rightElement, border = false }) => {
    return (
      <div className="h-full">
        <div className={`container mx-auto bg-white dark:bg-eerieBlack text-DarkNight dark:text-honeydew rounded-lg shadow overflow-hidden ${border ? 'border border-lightFlame' : ''} h-full flex flex-col`}>
          <div className="p-4 md:p-7 flex-grow">
            <div className="flex flex-row justify-between items-center mb-3">
              <div>
                <h1 className="text-xl md:text-2xl font-semibold inline-block">{title}</h1>
              </div>
              {rightElement}
            </div>
            <div className="flex-grow">
              {children}
            </div>
          </div>
        </div>
      </div>
    );
};