import React from 'react';
import { RoyaltiesSongRowProps } from '../utlis/types/GraphTypes';

const RoyaltiesSongDataTable: React.FC<RoyaltiesSongRowProps> = ({song}) => {
    return (
        <tr className="hover:bg-gray-200 dark:hover:bg-Night">
            <td className="border-t px-4 py-2 max-w-xs whitespace-normal overflow-hidden rounded-l-md">{song.title}</td>
            <td className="border-t px-4 py-2 max-w-xs whitespace-normal overflow-hidden">{song.writers}</td>
            <td className="border-t px-4 py-2 max-w-xs whitespace-normal overflow-hidden">{song.earnings}</td>
        </tr>
    );
}

export default RoyaltiesSongDataTable;
