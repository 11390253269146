import React, { useState, useEffect } from "react";
import { useUser } from "../../../../../global/storage/userContext";
import { CircleAlert } from 'lucide-react';
import { sendEmailVerification } from "../../utlis/services/settingsFunctions";
import FormModal from "../../../../../global/modals/formModal";
import ChangePasswordForm from "./changePassword";
import { GeneralButton2 } from "../../../../../global/ui/buttons";

const SecuritySection: React.FC = () => {
    const { user } = useUser();
    const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [message, setMessage] = useState("");
    const [formData, setFormData] = useState({ oldPassword: "", newPassword: "", confirmNewPassowrd: "",});

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (message) {
            timer = setTimeout(() => {
                setMessage("");
            }, 3000); // 3 seconds
        }
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [message]);

    const handleFormSubmit = async (e: React.FormEvent) => { 
        setError("");
        setSuccess("");
        e.preventDefault();
        if(!formData.newPassword || !formData.confirmNewPassowrd) {
            setError("Please enter a password");
            return;
        } else if (formData.newPassword !== formData.confirmNewPassowrd) {
            setError("Entered passwords are not the same")
            return;
        }

    }

    const handleInputChange = (field: string, value: string) => {
        setFormData(prevState => ({ ...prevState, [field]: value }));
        if (error) setError("");
    };

    const openModal = () => {
        setChangePasswordModalOpen(true);
    };

    const closeModal = () => {
        setChangePasswordModalOpen(false);
    };

    const handleStartEmailVerification = async () => {
        if (user?.id) {
            await sendEmailVerification(user.id, setMessage);
        } else {
            setMessage("An error occurred. Please try again later.");
        }
    };

    return (
        <div>
            <FormModal isOpen={isChangePasswordModalOpen} onClose={closeModal} title={'Change Password'} editing={true} 
                content={<ChangePasswordForm formData={formData} handleSubmit={handleFormSubmit} handleInputChange={handleInputChange} error={error} success={success} closeModal={closeModal}/>} 
            />
            <div className="flex justify-between items-center mb-5 text-sm">
                <p>Verify email</p>
                {user?.verified ? (
                    <GeneralButton2 text="Verified" disabled={true} />
                ) : (
                    <GeneralButton2 text="Verify" disabled={false} icon={<CircleAlert className="mr-2" />} onClick={handleStartEmailVerification} />
                )}
            </div>
            {message && <p className="text-yellow-500 text-sm mt-2">{message}</p>}
            {user?.verified && (
                <div className="flex justify-between items-center text-sm">
                    <p>Change password</p>
                    <GeneralButton2 text="Start" disabled={false} onClick={openModal} />
                </div>
            )}
        </div>
    );
};

export default SecuritySection;
