interface ChartData {
    name: string;
    earnings: number;
}

export interface GraphData {
    name: string;
    earnings: number;
}

export interface MonthlyLineChartProps {
    data: ChartData[];
}

export interface Song {
    id: number;
    title: string;
    writers: string
    earnings: number;
}

export interface RoyaltiesSongRowProps {
    song: Song;
}

export const songGraphHeaders = [
    { name: "Name" },
    { name: "Writers" },
    { name: "earnings" },
]

export const recentlyAddedSongHeaders = [
    { name: "Name" },
    { name: "Data Added" },
]
