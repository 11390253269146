import React, { useState, useEffect } from "react";
import { useUser } from "../../../../global/storage/userContext";
import DynamicTable from "../../../../global/dataTable/table";
import { recentlyAddedSongHeaders } from "../utlis/types/GraphTypes";
import RecentSongTable from "./components/recentSongTable";
import { fetchRecentlyAddedSongs, fetchSpotifyData } from "../utlis/services/songpageFunctions";
import { SectionContainer, SubSectionContainer } from "../../../../global/ui/containers";
import { Music, Users, PlayCircle, Clock, AlignEndHorizontal } from "lucide-react"
import { SongDT } from "../utlis/types/songTypes";
import { InfoBanner } from "../../../../global/ui/banners";

interface SpotifyData {
  followers: {
    total: number;
  };
  popularity?: number;
  genres?: string[];
}

const VerifiedDashboard: React.FC = () => {
	const { user } = useUser();
	const [songsData, setSongsData] = useState<SongDT[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [loading, setLoading] = useState(false);
	const [spotifyData, setSpotifyData] = useState<SpotifyData | null>(null);
	const [bannerMessage, setBannerMessage] = useState(user?.spotifyId ? true : false);
	
	useEffect(() => {
		fetchRecentlyAddedSongs(5, setSongsData, setLoading, setTotalCount);
		if (user?.spotifyId) {
			fetchSpotifyData(user.spotifyId, setSpotifyData, setLoading);
		}
	}, [user?.spotifyId]);

	return (
        <SectionContainer title="Dashboard">
			<InfoBanner message={ bannerMessage ? "" : "Link your Spotify Account in settings to see more data."} actionLabel="X" onAction={() => {setBannerMessage(true);}} />

			<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4 mb-8">
				<SubSectionContainer title="Total Songs" border={true} rightElement={<Music size={24} />}>
					<div className="text-2xl font-bold">{totalCount}</div>
					<p className="text-xs text-muted-foreground"></p>
				</SubSectionContainer>
				<SubSectionContainer title="Followers" border={true} rightElement={<Users size={24} />}>
					<div className="text-2xl font-bold">
						{spotifyData?.followers?.total?.toLocaleString() || '0'}
					</div>
					<p className="text-xs text-muted-foreground">{user?.spotifyId ? '' : 'Spotify link required'}</p>	
				</SubSectionContainer>
				<SubSectionContainer title="Popularity" border={true} rightElement={<AlignEndHorizontal size={24} />}>
					<div className="text-2xl font-bold">{spotifyData?.popularity || 0}/100</div>
					<p className="text-xs text-muted-foreground">{user?.spotifyId ? '' : 'Spotify link required'}</p>
				</SubSectionContainer>
				<SubSectionContainer title="Genres" border={true} rightElement={<PlayCircle size={24} />}>
					<div className="text-2xl font-bold">{spotifyData?.genres?.join(', ') || 'No genres found'}</div>
					<p className="text-xs text-muted-foreground">{user?.spotifyId ? '' : 'Spotify link required'}</p>
				</SubSectionContainer>
			</div>

			<SubSectionContainer title="Recently Added Songs" border={true}>
				<DynamicTable data={songsData} headers={recentlyAddedSongHeaders} loading={loading} renderRow={(song, index) => (<RecentSongTable key={index} song={song} />)} />
			</SubSectionContainer>
        </SectionContainer>
	);
};

export default VerifiedDashboard;
