import React from 'react';
import { useUser } from "../../../../global/storage/userContext";
import { SongRowProps } from '../utlis/types/songTypes';
import { Pencil, Trash2 } from 'lucide-react';

const SongDataTable: React.FC<SongRowProps> = ({ song, handleEdit = () => {}, openDeleteModal = () => {}}) => {
    const { user } = useUser();
    const writerNames = song.WorkWriters.map(writer => `${writer.Writer.firstName} ${writer.Writer.lastName}`).join(', ');
    const isEditable = song.status === 'saved';

    return (
        <tr className="hover:bg-gray-200 dark:hover:bg-Night">
            <td className="border-t px-4 py-2 max-w-xs whitespace-normal overflow-hidden rounded-l-md">{song.title}</td>
            <td className="border-t px-4 py-2 max-w-xs whitespace-normal overflow-hidden">{song.performingArtist}</td>
            <td className="border-t px-4 py-2 max-w-xs whitespace-normal overflow-hidden">{writerNames}</td>
            <td className="border-t px-4 py-2 whitespace-nowrap"><span className='bg-lightFlame p-1 rounded-lg text-white'>{song.status}</span></td>
            <td className="border-t px-4 py-2 text-right whitespace-nowrap rounded-r-md">
                {isEditable && (user?.auth === 'user')  && (
                    <>
                        <button className="ml-3 dark:text-white hover:text-lightFlame" onClick={() => handleEdit(song.id)}>
                            <Pencil />
                        </button>
                        <button className="ml-3 dark:text-white hover:text-lightFlame" onClick={() => openDeleteModal(song.id)}>
                            <Trash2 />
                        </button>
                    </>
                )}
            </td>
        </tr>
    );
}

export default SongDataTable;
