import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import DOMPurify from 'dompurify';
import gfm from 'remark-gfm';
import highlight from 'rehype-highlight';
import 'github-markdown-css';
import { GeneralButton2 } from '../../../../../global/ui/buttons';
import SpotifyLink from './OtherSectionsComponents/spotifyLink';

const OthersSection: React.FC = () => {
    const [modalContent, setModalContent] = useState('');
    const [showModal, setShowModal] = useState(false);

    const handleViewClick = async () => {
        try {
            const response = await fetch('/assets/files/terms-and-conditions.md');
            if (!response.ok) {
                throw new Error('Failed to load markdown file');
            }
            const markdownContent = await response.text();
            const sanitizedContent = DOMPurify.sanitize(markdownContent);
            setModalContent(sanitizedContent);
            setShowModal(true);
        } catch (error) {
            console.error('Error loading markdown file:', error);
        }
    };

    const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.currentTarget === event.target) {
            setShowModal(false);
        }
    };


    return (
        <div>
            <SpotifyLink />
            <div className="flex justify-between items-center text-sm">
                <p>Terms and Conditions</p>
                <GeneralButton2 text="View" onClick={handleViewClick} />
            </div>
            {showModal && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-75 overflow-y-auto h-full w-full z-50" onClick={handleBackdropClick}>
                    <div className="relative mx-auto p-8 w-11/12 max-w-7xl max-h-3/4 bg-gray-800 text-white shadow-lg rounded-md overflow-y-auto">
                        <div className="mt-3 markdown-body p-8 space-y-4">
                            <ReactMarkdown className="prose max-w-none" remarkPlugins={[gfm]} rehypePlugins={[highlight]} children={modalContent} />
                            <GeneralButton2 text="Close" onClick={() => setShowModal(false)} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OthersSection;
