import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { MonthlyLineChartProps } from '../types/GraphTypes';

const MonthlyLineChart: React.FC<MonthlyLineChartProps> = ({ data }) => (
  <ResponsiveContainer width="100%" height="100%">
    <LineChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip 
        wrapperStyle={{ backgroundColor: 'transparent', borderColor: 'transparent' }} // Custom styles
        contentStyle={{ backgroundColor: 'rgba(0,0,0,0.75)', borderColor: 'transparent' }} // Inner content styles
        cursor={false} // Disable cursor rectangle
      />
      {/* <Legend /> */}
      <Line type="monotone" dataKey="earnings" stroke="#8884d8" activeDot={{ r: 8 }} />
    </LineChart>
  </ResponsiveContainer>
);

export default MonthlyLineChart;
