import React from 'react';
import { FileText, Sheet } from 'lucide-react';

const StatementDataTable: React.FC = () => {
    return (
        <tr className="hover:bg-gray-200 dark:hover:bg-Night">
            <td className="border-t px-4 py-2 max-w-xs whitespace-normal overflow-hidden rounded-l-md"></td>
            <td className="border-t px-4 py-2 max-w-xs whitespace-normal overflow-hidden"></td>
            <td className="border-t px-4 py-2 max-w-xs whitespace-normal overflow-hidden"></td>
            <td className="border-t px-4 py-2 text-right whitespace-nowrap rounded-r-md">
                <>
                    <button className="ml-3 dark:bg-gray-300 hover:bg-gray-400 text-white hover:text-lightFlame">
                        <FileText />
                    </button>
                    <button className="ml-3 dark:bg-gray-300 hover:bg-gray-400text-white hover:text-lightFlame">
                        <Sheet />
                    </button>
                </>
            </td>
        </tr>
    );
}

export default StatementDataTable;
