/**
 * Retrieves a specific key's data from session storage stored under 'UserData'.
 * @param key The key of the data to retrieve.
 */
export function getUserData(key: string) {
    const userDataJson = sessionStorage.getItem('UserData');
    if (userDataJson) {
        const userData = JSON.parse(userDataJson);
        return userData[key];
    }
    return null;
}

/**
 * Saves a specific key's data in session storage under 'UserData'.
 * @param key The key under which to store the data.
 * @param value The data to store.
 */
export function setUserData(key: string, value: any) {
    const userDataJson = sessionStorage.getItem('UserData');
    const userData = userDataJson ? JSON.parse(userDataJson) : {};
    if (value === null) {
        delete userData[key];
    } else {
        userData[key] = value;
    }
    sessionStorage.setItem('UserData', JSON.stringify(userData));
}

// Add this function
export function clearUserData() {
    sessionStorage.removeItem('UserData');
}
