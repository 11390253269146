import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider } from './global/storage/userContext';
import LoginPage from './pages/login/loginPage';
import PrivateRoute from './global/privateRoute';
import Main from './pages/main/main';
import PublicRoute from './global/publicRoute';
import ErrorBoundary from './global/pages/errorBoundary';
import ForgotPasswordPage from './pages/forgotPassword/forgotPassword';
import ResetPasswordPage from './pages/forgotPassword/resetPassword';
import EmailVerificationPage from './pages/login/emailVerifyPage';
import AccountSetUpInfoPage from './pages/signup/accountSetUpInfoPage';
import ThemeProvider from './global/ThemeContext';

function App() {
  return (
    <UserProvider>
      <ThemeProvider>
        <Router>
            <ErrorBoundary>
              <AppContent />
            </ErrorBoundary>
        </Router>
      </ThemeProvider>
    </UserProvider>
  );
}

function AppContent() {

  return (
    <div>
      <Routes>
        <Route path="/login" element={<PublicRoute redirectPath="/"><LoginPage /></PublicRoute>} />
        <Route path="/signup" element={<PublicRoute redirectPath="/"><AccountSetUpInfoPage /></PublicRoute>} />
        <Route path="/forgot-password" element={ <PublicRoute redirectPath="/"><ForgotPasswordPage /></PublicRoute> } />
        <Route path="/reset-password/:token" element={ <PublicRoute redirectPath="/"><ResetPasswordPage /></PublicRoute> } />
        <Route path="/user/verify-email/:token" element={<EmailVerificationPage />} />
        <Route path="/*" element={<PrivateRoute><Main /></PrivateRoute>} />
      </Routes>
    </div>
  );
}

export default App;
