import React, { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import { changeUserPassword } from '../../utlis/services/settingsFunctions';
import { InfoBanner } from '../../../../../global/ui/banners';
import { GeneralButton } from '../../../../../global/ui/buttons';


interface PasswordFormProps {
    formData: { 
        oldPassword: string,
        newPassword: string, 
        confirmNewPassowrd: string,
    };
    handleSubmit: React.FormEventHandler<HTMLFormElement>;
    handleInputChange: (field: string, value: string) => void;
    error: string;
    success: string;
    closeModal: () => void;
}

const ChangePasswordForm: React.FC<PasswordFormProps> = ({ formData, handleSubmit, handleInputChange, error, success, closeModal }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [submitError, setSubmitError] = useState('');

    const handleStandardInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleInputChange(e.target.name, e.target.value);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    const handleChangePassword = async (e: React.FormEvent) => {
        e.preventDefault(); 

        if (formData.oldPassword === '') {
            setSubmitError('Old password is required');
            return;
        }
        if (formData.newPassword === '') {
            setSubmitError('New password is required');
            return;
        }
        if (formData.confirmNewPassowrd === '') {
            setSubmitError('Confirm new password is required');
            return;
        }
        if (formData.newPassword !== formData.confirmNewPassowrd) {
            setSubmitError('New passwords do not match');
            return;
        }
        if (formData.oldPassword === formData.newPassword) {
            setSubmitError('New password cannot be the same as old password');
            return;
        }
        const { oldPassword, newPassword } = formData;

        const result = await changeUserPassword({ oldPassword, newPassword }, setSubmitError);

        if (result) {
            setSubmitError('');
            closeModal();
        }
    };

    return (
        <form onSubmit={handleChangePassword} className="space-y-6">
            {submitError && <InfoBanner message={submitError} />}
            {error && <InfoBanner message={error} />}
            {success && <InfoBanner message={success} />}
            <div className="flex flex-col md:flex-row items-center relative">
                <label htmlFor="oldPassword" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0 md:ml-20 lg:ml-52">Old Password<span className="text-red-500 text-base ml-1">*</span></label>
                <div className="w-full md:w-2/5 relative">
                    <input 
                        type={showPassword ? "text" : "password"} 
                        id="oldPassword" 
                        name="oldPassword" 
                        className="border border-flame rounded-md bg-gray-200 dark:bg-Night p-2 px-4 w-full pr-10" 
                        value={formData.oldPassword} 
                        onChange={handleStandardInputChange} 
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        {showPassword ? (
                            <EyeOff className="cursor-pointer" onClick={togglePasswordVisibility} />
                        ) : (
                            <Eye className="cursor-pointer" onClick={togglePasswordVisibility} />
                        )}
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row items-center relative">
                <label htmlFor="newPassword" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0 md:ml-20 lg:ml-52">New Password<span className="text-red-500 text-base ml-1">*</span></label>
                <div className="w-full md:w-2/5 relative">
                    <input 
                        type={showPassword ? "text" : "password"} 
                        id="newPassword" 
                        name="newPassword" 
                        className="border border-flame rounded-md bg-gray-200 dark:bg-Night p-2 px-4 w-full pr-10" 
                        value={formData.newPassword} 
                        onChange={handleStandardInputChange} 
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        {showPassword ? (
                            <EyeOff className="cursor-pointer" onClick={togglePasswordVisibility} />
                        ) : (
                            <Eye className="cursor-pointer" onClick={togglePasswordVisibility} />
                        )}
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row items-center relative">
                <label htmlFor="confirmNewPassowrd" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0 md:ml-20 lg:ml-52">Confirm New Password<span className="text-red-500 text-base ml-1">*</span></label>
                <div className="w-full md:w-2/5 relative">
                    <input 
                        type={showPassword ? "text" : "password"} 
                        id="confirmNewPassowrd" 
                        name="confirmNewPassowrd" 
                        className="border border-flame rounded-md bg-gray-200 dark:bg-Night p-2 px-4 w-full pr-10" 
                        value={formData.confirmNewPassowrd} 
                        onChange={handleStandardInputChange} 
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        {showPassword ? (
                            <EyeOff className="cursor-pointer" onClick={togglePasswordVisibility} />
                        ) : (
                            <Eye className="cursor-pointer" onClick={togglePasswordVisibility} />
                        )}
                    </div>
                </div>
            </div>
            <div className="flex justify-end items-center mb-10">
                <GeneralButton type='button' text="Cancel" onClick={closeModal} space={true}/>
                <GeneralButton type="submit" text="Submit" />
            </div>
        </form>
    );
}

export default ChangePasswordForm;
