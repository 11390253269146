import React, { useState, useEffect } from "react";
import SongDetailDownloadModal from "../songPage/songDetailsDownloadModal";
import FormModal from "../../../../global/modals/formModal";
import DynamicTable from "../../../../global/dataTable/table";
import SongDataTable from "../songPage/songDataTable";
import Pagination from "../../../../global/dataTable/pagination";
import { SongDT, useSongHeaders } from "../utlis/types/songTypes";
import { fetchSongs } from "../utlis/services/songpageFunctions";
import CustomDropdown from "../../../../global/ui/customDropdown";
import { fetchUserDropdown } from "../utlis/services/exportpageFunctions";
import { User } from "../utlis/types/exportTypes";
import { GeneralButton } from "../../../../global/ui/buttons";
import { SectionContainer } from "../../../../global/ui/containers";

const ExportScreen: React.FC = () => {
    const [songsData, setSongsData] = useState<SongDT[]>([]);
    const [selectedUser, setSelectedUser] = useState<string>("0");
    const [selectedUserName, setSelectedUserName] = useState<string>("");
    const [userDropdown, setUserDropdown] = useState<User[]>([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [totalPages, setTotalPages] = useState(0);
    const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const headers = useSongHeaders();

    const userOptions = userDropdown.map(user => ({ label: `${user.firstName} ${user.lastName}`, value: `${user.id}`}));

    useEffect(() => {
        if (selectedUser && selectedUser !== "0") {
            const debounceTimer = setTimeout(() => {
                fetchSongs(currentPage, pageSize, "", setLoading, setTotalPages, setSongsData, selectedUser);
            }, 600);
            return () => clearTimeout(debounceTimer);
        } else {
            setSongsData([]);
            setTotalPages(0);
        }
    }, [currentPage, pageSize, selectedUser]);

    useEffect(() => {
        let debounceCheck: NodeJS.Timeout | undefined;
        clearTimeout(debounceCheck);
        debounceCheck = setTimeout(() => {
            fetchUserDropdown(setUserDropdown);
        }, 500);
        return () => clearTimeout(debounceCheck);
    }, []);

    const paginatedSongs = () => {
        const startIndex = (currentPage - 1) * pageSize;
        return songsData.slice(startIndex, startIndex + pageSize);
    };
    
    const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPageSize(Number(event.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handleUserChange = (userId: string) => {
        setSelectedUser(userId);
        const selectedUserObj = userDropdown.find(user => user.id.toString() === userId);
        setSelectedUserName(selectedUserObj ? `${selectedUserObj.firstName}_${selectedUserObj.lastName}` : "");
    };
    
    const openDownloadModal = () => {
        setIsDownloadModalOpen(true);
    };
    
    const closeModal = () => {
        setIsDownloadModalOpen(false);
    };
    
    return (
        <SectionContainer title="Song Export" rightElement={<GeneralButton type="button" onClick={openDownloadModal} text="Export to excel" />}>    
            <FormModal isOpen={isDownloadModalOpen} onClose={closeModal} title={"Download"} editing={false} content={<SongDetailDownloadModal closeModal={closeModal} selectedUser={selectedUser} selectedUserName={selectedUserName}/>}/>
            <CustomDropdown value={selectedUser} onChange={handleUserChange} options={userOptions}  placeholder="Select a User" id="Writer"/>
            <div className="overflow-x-auto mt-3">
                <DynamicTable data={paginatedSongs()} headers={headers} 
                    renderRow={(song, index) => ( 
                        <SongDataTable key={song.id} song={song} />
                    )}
                    loading={loading} 
                />
                {songsData.length > 0 && (
                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} 
                        onPageSizeChange={handlePageSizeChange} pageSize={pageSize} 
                    />
                )}
            </div>
        </SectionContainer>
    );
};
  
export default ExportScreen;