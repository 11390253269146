import React, { useState, useEffect } from "react";
import UserForm from "./userForm";
import UserDataTable from "./userDataTable";
import { authOptions, userHeaders, Users } from "../utlis/types/userTypes";
import { deleteUser, fetchUsers, formSubmit } from "../utlis/services/userPageFunctions";
import CustomDropdown from "../../../../global/ui/customDropdown";
import { GeneralButton } from "../../../../global/ui/buttons";
import { useUser } from "../../../../global/storage/userContext";
import FormModal from "../../../../global/modals/formModal";
import DynamicTable from "../../../../global/dataTable/table";
import SearchInput from "../../../../global/dataTable/searchInput";
import DeleteModal from "../../../../global/modals/deleteModal";
import Pagination from "../../../../global/dataTable/pagination";
import { InfoBanner } from "../../../../global/ui/banners";
import { SectionContainer } from "../../../../global/ui/containers";

const UsersScreen: React.FC = () => {
    const { user } = useUser();
    const [usersData, setUsersData] = useState<Users[]>([]);
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const [auth, setAuth] = useState('');
    const [selectedUserId, setSelectedUserId] = useState(0);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({ artistName: '', firstName: '', lastName: '', email: '', password: '', account_type: '' });
    const [message, setMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        fetchUsers(currentPage, pageSize, searchQuery, auth, setLoading, setTotalPages, setUsersData);
    }, [currentPage, pageSize, searchQuery, auth]);

    const handleEdit = (users: Users) => {
        openFormModal(users);
    };

    const openDeleteModal = (userId: number) => {
        setSelectedUserId(userId);
        setDeleteModalOpen(true);
    };

    const handleInputChange = (name: string, value: string) => {
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSearchChange = (query: string) => {
        setSearchQuery(query);
    };

    const handleDelete = async () => {
        closeModal();
        if (!selectedUserId) return;
        const response = await deleteUser(selectedUserId);

        if (response === true) {
            fetchUsers(currentPage, pageSize, searchQuery, auth, setLoading, setTotalPages, setUsersData);
        }
    }

    const openFormModal = (user?: Users) => {
        if (user) {
            setEditing(true);
            setSelectedUserId(user.id);
            setFormData({
                artistName: user.artistName,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                password: user.password,
                account_type: user.account_type
            });
        } else {
            setEditing(false);
            setFormData({ artistName: '', firstName: '', lastName: '', email: '', password: '', account_type: '' });
        }
        setIsModalOpen(true);
    };

    const handleFormSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setMessage('');
        if (!formData.firstName || !formData.lastName || !formData.email || !formData.account_type) {
            setMessage('All fields are required.');
            return;
        }
        const endpoint = editing ? `/api/user/${selectedUserId}` : '/api/user/signup?devCreate=true';
        const method = editing ? 'PUT' : 'POST';    
        const response = await formSubmit(formData, setMessage, endpoint, method);

        if (response === true) {
            fetchUsers(currentPage, pageSize, searchQuery, auth, setLoading, setTotalPages, setUsersData);
            setMessage(editing ? 'User updated successfully.' : 'User added successfully.');
            setTimeout(() => {
                closeModal();
            }, 2000);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setDeleteModalOpen(false);
        setFormData({ artistName: '', firstName: '', lastName: '', email: '', password: '', account_type: '' });
        setMessage('');
    }

    const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPageSize(Number(event.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    return (
        <SectionContainer title="Users" rightElement={user?.auth === "developer" && <GeneralButton text="Add User +" onClick={() => setIsModalOpen(true)} />}>
            <InfoBanner message="Do Not Make Random Changes to data as this can cause issues with the app and users data. (Verify First before making changes)" />
            <FormModal isOpen={isModalOpen} onClose={closeModal} title={editing ? 'Edit User' : 'Add User'} editing={editing} 
                content={<UserForm formData={formData} handleSubmit={handleFormSubmit} handleInputChange={handleInputChange} message={message}  closeModal={closeModal} setMessage={setMessage} editing={editing}/>} 
            />
            <DeleteModal isOpen={isDeleteModalOpen} onClose={closeModal} onConfirm={handleDelete}><p className="mt-1">Are you sure you want to delete this User this can not be undone!</p></DeleteModal>
            <div className="overflow-x-auto mt-3">
                <div className="mb-3 flex flex-col md:flex-row md:items-center md:justify-between">
                    <SearchInput placeholder="Search users..." onSearchChange={(query) => handleSearchChange(query)} />
                </div>
                <div className="mb-3 flex flex-col md:flex-row md:items-center md:justify-between">
                    <CustomDropdown value={auth} onChange={(value) => setAuth(value)} options={authOptions} id="auth" placeholder="Select account type"/>
                </div>
                <DynamicTable data={usersData} headers={userHeaders} renderRow={(users, index) => (
                    <UserDataTable key={index} users={users} handleEdit={() => handleEdit(users)} openDeleteModal={() => openDeleteModal(users.id)} />
                )} loading={loading} />
                {usersData.length > 0 && ( 
                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} onPageSizeChange={handlePageSizeChange} pageSize={pageSize} />
                )}
            </div>
        </SectionContainer>
    );
};

export default UsersScreen;