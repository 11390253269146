export const changeUserPassword = async (formData: { oldPassword: string; newPassword: string }, setError: (newData: string) => void) => {
    try {
        const response = await fetch('/api/user/change-password', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ formData }) // Sending formData as part of the request body
        });

        if (response.ok) {
            const data = await response.json();
            return data; // Return success message or any other data if needed
        } else {
            const data = await response.json();
            setError(data.error || 'Failed to change password');
            console.error('Failed to change password:', data.error);
        }
    } catch (error) {
        console.error('Error changing password:', error);
        setError('An error occurred while changing the password');
    }
};

export const sendEmailVerification = async (userId: number, setMessage: (newData: string) => void) => {
    try {
        const response = await fetch('/api/user/verify-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId }),
            credentials: 'include',
        });

        if (response.ok) {
            const data = await response.json();
            setMessage(data.message);
        } else {
            const data = await response.json(); 
            setMessage(data.message);
        }
    } catch (error) {
        console.error('Error sending email verification:', error);
        setMessage('An error occurred. Please try again later.');
    }
};

export const updateUserSettings = async (newSettings: { DarkMode: boolean }) => {
    try {
        const response = await fetch('/api/user/settings', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newSettings),
        });
        if (!response.ok) {
            const errorData = await response.json();
            if (response.status === 429) {
                alert(errorData.message);
            } else {
                throw new Error('Failed to update settings');
            }
        } else {
            const data = await response.json();
            return true;
        }
    } catch (error) {
        console.error('Error updating user settings:', error);
        return false;
    }
};

export const fetchArtistPreview = async (artistId: string, setMessage: (newData: string) => void, setSpotifyData: (newData: any) => void) => {
    const response = await fetch(`/api/user/spotify/search?artistId=${encodeURIComponent(artistId)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });
    if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 429) {
            setMessage(errorData.message);
        } else {
            setMessage('Failed to fetch artist preview');
        }
    }
    const data = await response.json();
    setSpotifyData(data.artist);
    setMessage('Artist preview found dont forget to save');
};

export const updateUserProfile = async (key: string, value: string) => {
    try {
        const response = await fetch('/api/user/update-profile', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ key, value }),
        });
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to update user profile');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error updating user profile:', error);
        throw error;
    }
}