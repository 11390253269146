import React, { ReactNode } from 'react';

interface FormModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    content: ReactNode;
    editing: boolean;
}

const FormModal: React.FC<FormModalProps> = ({ isOpen, onClose, title, content, editing }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-gray-300 dark:bg-eerieBlack py-4 px-10 rounded-lg shadow-lg max-w-sm md:max-w-xl lg:max-w-7xl w-full ">
        <div className="flex justify-between items-center mb-10">
            <h2 className="text-left text-2xl text-flame">{title}</h2>
            <button className="text-flame text-2xl" onClick={onClose}>&times;</button>
        </div>
        <div className="">{content}</div>
      </div>
    </div>
  );
};

export default FormModal;
