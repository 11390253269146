import React, { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from './storage/userContext';
import LoadingScreen from './pages/loadingScreen';

interface PrivateRouteProps {
  children: ReactNode;
  userOnly?: boolean
  adminOnly?: boolean;
  developerOnly?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, userOnly = false, adminOnly = false, developerOnly = false }) => {
  const { user, loading } = useUser();
  const location = useLocation();

  if (loading) {
    return <LoadingScreen />;
  }

  if (!user) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  if (userOnly && user.auth !== 'user') {
    return <Navigate to="/" replace />;
  } 

  if (adminOnly && (user.auth !== 'admin' && user.auth !== 'developer')) {
    return <Navigate to="/" replace />;
  } 

  if (developerOnly && user.auth !== 'developer') {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
