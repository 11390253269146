import React, { useState, useEffect } from "react";
import DynamicTable from "../../../../global/dataTable/table";
import Pagination from "../../../../global/dataTable/pagination";
import SearchInput from "../../../../global/dataTable/searchInput";
import { statementHeaders } from "../utlis/types/StatementTypes";
import StatementDataTable from "./statementDataTable";
import { SectionContainer } from "../../../../global/ui/containers";

const StatementsScreen: React.FC = () => {
    const [statementData, setStatementData] = useState([]);

    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [totalPages, setTotalPages] = useState(0);

    const [loading, setLoading] = useState(false);

    const handleSearchChange = (query: string) => {
        setSearchQuery(query);
        setCurrentPage(1);
    };

    const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPageSize(Number(event.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    return ( 
        <SectionContainer title="Statements">  
            <SearchInput placeholder="Search statement dates..." onSearchChange={(query) => handleSearchChange(query)} />
                <div className="overflow-x-auto mt-3">
                    <DynamicTable data={statementData} headers={statementHeaders} 
                        renderRow={(statement, index) => (  <StatementDataTable />)}
                        loading={loading} 
                    />
                    {statementData.length > 0 && (
                        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} 
                            onPageSizeChange={handlePageSizeChange} pageSize={pageSize} 
                        />
                    )}
            </div>
        </SectionContainer>
    );
};
  
export default StatementsScreen;  