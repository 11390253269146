import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import allTabs from './navTabs.json';
import { useUser } from '../../global/storage/userContext';
import NavBar from '../../global/navBar';
import SongScreen from './components/songPage/songScreen';
import DashboardScreen from './components/dashboardPage/dashboardScreen';
import WritersScreen from './components/writersPage/writersScreen';
import StatementsScreen from './components/statementsPage/statementScreen';
import RoyaltiesScreen from './components/royaltiesPage/royaltiesScreen';
import SongFormScreen from './components/songPage/songFormScreen';
import ExportScreen from './components/exportPage/exportScreen';
import SettingsScreen from './components/settingsPage/settingsScreen';
import PrivateRoute from '../../global/privateRoute';
import JobsScreen from './components/jobsPage/jobsScreen';
import UsersScreen from './components/userPage/userScreen';

// Custom hook to get window size
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

interface Tab {
  id: string;
  label: string;
  route: string;
  visibleTo: string[];
}

type UserRole = 'user' | 'admin' | 'developer';

const DashMain: React.FC = () => {
  const { width } = useWindowSize();
  const [isCollapsed, setIsCollapsed] = useState(width < 1024);
  const { user } = useUser();
  const [filteredTabs, setFilteredTabs] = useState<Tab[]>([]);
  const [filteredFooterTabs, setFilteredFooterTabs] = useState<Tab[]>([]);
  const [activeTab, setActiveTab] = useState('dashboard');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const userRole: UserRole = (user?.auth as UserRole) || 'user';
    const isVerified = user?.verified ?? false;

    const filterTabs = (tabs: Tab[]) => {
      if (!isVerified) {
        return tabs.filter(tab => ['dashboard', 'settings'].includes(tab.id));
      }
      return tabs.filter(tab => tab.visibleTo.includes(userRole));
    };

    const mainTabs = allTabs.filter(tab => !['settings', 'support', 'telescope'].includes(tab.id));
    const footerTabs = allTabs.filter(tab => ['settings', 'support', 'telescope'].includes(tab.id));

    setFilteredTabs(filterTabs(mainTabs));
    setFilteredFooterTabs(filterTabs(footerTabs));
  }, [user]);

  useEffect(() => {
    const currentPath = location.pathname;
    
    if (!user?.verified && !['/settings', '/'].includes(currentPath)) {
      setActiveTab('dashboard');
      navigate('/');
    } else if (currentPath === '/') {
      if (user?.auth === "admin" || user?.auth === "developer") {
        setActiveTab('export')
        navigate('/export');
      } else  {
        setActiveTab('dashboard');
      }
    } else {
      const foundTab = filteredTabs.concat(filteredFooterTabs).find(tab => `/${tab.id}` === currentPath);
      setActiveTab(foundTab ? foundTab.id : '');
    }
  }, [location, user, navigate, filteredTabs, filteredFooterTabs]);


  return (
    <div className="flex flex-col">
      <header>
        <NavBar 
          tabs={filteredTabs} 
          tabsFooter={filteredFooterTabs} 
          isCollapsed={isCollapsed} 
          setIsCollapsed={setIsCollapsed} 
          isactiveTab={activeTab} 
          setActiveTab={setActiveTab}
        />
      </header>

      <div className={`flex-1 transition-all duration-500 min-h-screen bg-gray-200 dark:bg-DarkNight text-honeydew text-sm ${isCollapsed ? 'md:pl-20' : 'md:pl-20 lg:pl-64'}`}>
        <Routes>
          <Route path="/" element={<DashboardScreen />} />
          <Route path="/settings" element={<SettingsScreen />} />
          {user?.verified && (
            <>
              <Route path="/songs" element={<PrivateRoute userOnly={true}><SongScreen /></PrivateRoute>} />
              <Route path="/songs/form/:songId?" element={<PrivateRoute userOnly={true}><SongFormScreen /></PrivateRoute>} />
              <Route path="/writers" element={<PrivateRoute userOnly={true}><WritersScreen /></PrivateRoute>} />
              <Route path="/statements" element={<PrivateRoute userOnly={true}><StatementsScreen /></PrivateRoute>} />
              <Route path="/royalties" element={<PrivateRoute userOnly={true}><RoyaltiesScreen /></PrivateRoute>} />
              <Route path="/support" element={<Route path="/support" element={<div>Support</div>} />} />

              <Route path="/export/*" element={<PrivateRoute adminOnly={true}><ExportScreen /></PrivateRoute>} />
              <Route path="/jobs/*" element={<PrivateRoute adminOnly={true}><JobsScreen /></PrivateRoute>} />
              <Route path="/users" element={<PrivateRoute adminOnly={true}><UsersScreen /></PrivateRoute>} />
            </>
          )}
        </Routes>
      </div>
    </div>
  );
};  

export default DashMain;