import React, { useState } from 'react';
import { sendEmailVerification } from '../../main/components/utlis/services/settingsFunctions';


interface EmailVerificationFormProps {
  userId: number;
  onVerificationSent: () => void;
  onSkip: () => void;
}

const EmailVerificationForm: React.FC<EmailVerificationFormProps> = ({ userId, onVerificationSent, onSkip }) => {
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSendVerification = async () => {
    setIsLoading(true);
    try {
      await sendEmailVerification(userId, setMessage);
      onVerificationSent();
    } catch (error) {
      setMessage('Failed to send verification email. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <p className="text-sm text-honeydew mb-5 mt-5">Please verify your email address to continue.</p>
      {message && <p className="text-sm text-flame">{message}</p>}
      <button onClick={handleSendVerification} disabled={isLoading}  className="w-full py-2 rounded-lg bg-flame hover:bg-red-600 text-white font-medium disabled:opacity-50">
        {isLoading ? 'Sending...' : 'Send Verification Email'}
      </button>
      <div className="text-sm text-flame text-center cursor-pointer" onClick={onSkip}>Skip for now</div>
    </div>
  );
};

export default EmailVerificationForm;