import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { LayoutList, ListMusic, NotebookPen,LineChart, Settings, Headset, 
  ClipboardMinus, LogOut, ScrollText, Telescope, FileQuestion, FolderUp, 
  ArrowRightLeft, UserRound, BriefcaseBusiness } from 'lucide-react';
import { useUser } from './storage/userContext';

const iconMap: { [key: string]: JSX.Element } = {
  dashboard: <LayoutList />,
  songs: <ListMusic />,
  writers: <NotebookPen />,
  royalties : <LineChart />,
  settings: <Settings />,
  support: <Headset />,
  logout: <LogOut />,
  statements: <ClipboardMinus />,
  request:  <ScrollText />,
  telescope: <Telescope />,
  query: <FileQuestion />,
  export: <FolderUp />,
  "song-status": <ArrowRightLeft />,
  "users": <UserRound />,
  jobs: <BriefcaseBusiness />
};

type Tab = {
    id: string;
    label: string;
    route: string;
};

type TabFooter = {
  id: string;
  label: string;
  route: string;
};
  
type NavBarProps = {
    tabs: Tab[];
    tabsFooter: TabFooter[];
    isactiveTab: string;
    setActiveTab: React.Dispatch<React.SetStateAction<string>>;
    isCollapsed: boolean;
    setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};

const NavBar:React.FC<NavBarProps> = ({ tabs, tabsFooter, isCollapsed, setIsCollapsed, isactiveTab, setActiveTab}) => {
    const { logout } = useUser();
    const navigate = useNavigate();

    const handleLogout = async () => {
        await logout();
        navigate('/login');
        window.location.reload();
    };

    const isMediumScreen = useMediaQuery({ minWidth: 768, maxWidth: 1024 });

    const handleNavLinkClick = (tabId: string) => {
        setActiveTab(tabId);
        if (isMediumScreen) {
            setIsCollapsed(true);
        }
    };

    const toggleNavbar = () => {
      setIsCollapsed(!isCollapsed);
    };

    return (
    <>
      <aside className={`hidden bg-gray-300 dark:bg-black text-DarkNight dark:text-honeydew md:flex fixed inset-y-0 left-0 ${isCollapsed ? 'w-20' : 'w-64'} flex flex-col justify-between transition-width duration-500 text-sm z-50`}>
        <div>
          <div className='flex justify-start py-3'>
            <button onClick={toggleNavbar} className="flex items-center justify-center">
              <img src="/assets/fidiaLogo2.png" alt="thefidia" className={`transition-all duration-500 ${isCollapsed ? 'h-10 px-5' : 'h-10 px-5 mt-4 mb-3'}`} />
              <h1 className={`flex items-center text-2xl mt-3 transition-opacity duration-300 delay-300 ${isCollapsed ? 'overflow-hidden opacity-0' : 'opacity-100'}`} style={{ fontFamily: 'serifReclare, serif' }}> theFidia </h1>
            </button>
          </div>
          <ul>
            {tabs.map((tab) => (
              <li key={tab.id} className="tooltip relative px-4">
                <NavLink to={tab.route} className={`flex items-center mb-1 py-3  rounded-xl cursor-pointer  ${isactiveTab === tab.id ? 'bg-flame hover:bg-flame text-white dark:text-white' : 'hover:bg-gray-400 dark:hover:bg-gray-700'}`} onClick={() => { handleNavLinkClick(tab.id); }}>
                  {isCollapsed && (
                    <>
                      <span className="items-center justify-center p-1 ml-2">{iconMap[tab.id]}</span>
                      <span className="tooltiptext">{tab.label}</span>
                    </>
                  )}
                  {!isCollapsed && (
                    <>
                      <span className="text-xl ml-2">{iconMap[tab.id]}</span>
                      <span className="ml-3">{tab.label} </span>
                    </>
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="py-2">
          <ul>
            {tabsFooter.map((tab) => (
              <li key={tab.id} className="tooltip relative px-4">
                <NavLink to={tab.route} className={`flex items-center mb-1 py-3  rounded-xl cursor-pointer ${isactiveTab === tab.id ? 'bg-flame hover:bg-flame text-white dark:text-white' : 'hover:bg-gray-400 dark:hover:bg-gray-700'}`} onClick={() => { handleNavLinkClick(tab.id); }}>
                  {isCollapsed && (
                    <>
                      <span className="items-center justify-center p-1 ml-2">{iconMap[tab.id]}</span>
                      <span className="tooltiptext">{tab.label}</span>
                    </>
                  )}
                  {!isCollapsed && (
                    <>
                      <span className="text-xl ml-2">{iconMap[tab.id]}</span>
                      <span className="ml-3">{tab.label} </span>
                    </>
                  )}
                </NavLink>
              </li>
            ))}
            <li className="tooltip relative px-4">
              <span onClick={handleLogout} className="flex items-center mb-1 py-3  rounded-xl cursor-pointer hover:bg-gray-700">
                {isCollapsed && (
                    <>
                      <span className="items-center justify-center p-1 ml-2"><LogOut /></span>
                      <span className="tooltiptext">Logout</span>
                    </>
                  )}
                {!isCollapsed && (
                  <>
                    <span className="text-xl ml-2"><LogOut /></span>
                    <span className="ml-3">Logout </span>
                  </>
                )}
              </span>              
            </li>
          </ul>
        </div>
      </aside>

      <aside className={`md:hidden fixed inset-y-0 left-0 z-50 transition-all duration-300 ease-in-out ${isCollapsed ? 'w-20' : 'w-full bg-gray-300 dark:bg-black text-DarkNight dark:text-honeydew'} ${isCollapsed ? 'h-20' : 'h-full'}`}>
        <div className='flex justify-start py-3 px-5'>
          <button onClick={toggleNavbar} className=" flex items-center justify-center w-full">
            <img src="/assets/fidiaLogo2.png" alt="thefidia" className="transition-all duration-300 h-10" />
            <h1 className={`flex items-center text-2xl mt-3 ml-2 transition-all duration-100 ${isCollapsed ? 'hidden' : 'block'}`} style={{ fontFamily: 'serifReclare, serif' }}>theFidia </h1>          
          </button>
        </div>
        {!isCollapsed && (
          <div className="p-5">
            <ul>
              {tabs.map((tab) => (
                <li key={tab.id} className="my-2">
                  <NavLink to={tab.route} className={`flex items-center py-2 rounded-lg cursor-pointer text-xl ${isactiveTab === tab.id ? 'bg-flame hover:bg-flame text-white dark:text-white' : 'hover:bg-gray-400 dark:hover:bg-gray-700'}`} onClick={() => {
                    setActiveTab(tab.id);
                    setIsCollapsed(true);
                  }}>
                    <span className="ml-2">{iconMap[tab.id]}</span>
                    <span className="ml-3">{tab.label}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
            <ul>
              {tabsFooter.map((tab) => (
                <li key={tab.id} className="my-2">
                  <NavLink to={tab.route} className={`flex items-center py-2 rounded-lg cursor-pointer text-xl ${isactiveTab === tab.id ? 'bg-flame hover:bg-flame text-white dark:text-white' : 'hover:bg-gray-400 dark:hover:bg-gray-700'}`} onClick={() => {
                    setActiveTab(tab.id);
                    setIsCollapsed(true);
                  }}>
                    <span className="ml-2">{iconMap[tab.id]}</span>
                    <span className="ml-3">{tab.label}</span>
                  </NavLink>
                </li>
              ))}
              <li>
                <span onClick={handleLogout} className="flex items-center py-2 rounded-lg cursor-pointer text-xl hover:bg-gray-700">
                  <span className="ml-2"><LogOut /></span>
                  <span className="ml-3">Logout</span>
                </span>              
              </li>
            </ul>
          </div>
        )}
      </aside>
    </>
  );
};

export default NavBar;

