import React from 'react';
import { UserRowProps } from '../utlis/types/userTypes';
import { Pencil, Trash2 } from 'lucide-react';
import { useUser } from '../../../../global/storage/userContext';

const UserDataTable: React.FC<UserRowProps> = ({ users, handleEdit, openDeleteModal }) => {
    const { user } = useUser();
    return (
        <tr className="hover:bg-gray-200 dark:hover:bg-Night">
            <td className="border-t px-4 py-2 max-w-xs whitespace-normal overflow-hidden rounded-l-md">{users.firstName} {users.lastName}</td>
            <td className="border-t px-4 py-2 max-w-xs whitespace-normal overflow-hidden">{users.email}</td>
            <td className="border-t px-4 py-2 max-w-xs whitespace-nowrap">
                {users.account_type.charAt(0).toUpperCase() + users.account_type.slice(1)}
            </td>
            <td className="border-t px-4 py-2 text-right whitespace-nowrap rounded-r-md">
                {user?.auth === 'developer' ? (
                    <>
                        { users.firstName !== 'developer' && (
                            <button onClick={() => handleEdit(users.id)} className="dark:text-white hover:text-lightFlame">
                                <Pencil />
                            </button>
                        )}
                        {users.firstName !== 'developer' && (
                            <button onClick={() => openDeleteModal(users.id)} className="ml-3 dark:text-white hover:text-lightFlame">
                                <Trash2 />
                            </button>
                        )}
                    </>
                ) : null}
            </td>
        </tr>
    );
}

export default UserDataTable;
