import React, { useState, useEffect } from "react";
import { SongDT, useSongHeaders } from "../utlis/types/songTypes";
import { useNavigate } from 'react-router-dom';
import Pagination from "../../../../global/dataTable/pagination";
import DynamicTable from "../../../../global/dataTable/table";
import SearchInput from "../../../../global/dataTable/searchInput";
import SongDataTable from "./songDataTable";
import DeleteModal from "../../../../global/modals/deleteModal";
import { fetchSongs, deleteSong } from "../utlis/services/songpageFunctions";
import { GeneralButton } from "../../../../global/ui/buttons";
import { SectionContainer } from "../../../../global/ui/containers";

const SongScreen: React.FC = () => {
    const navigate = useNavigate();

    const [songsData, setSongsData] = useState<SongDT[]>([]);
    const [selectedSongId, setSelectedSongId] = useState<number | null>(null);

    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [totalPages, setTotalPages] = useState(0);

    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const headers = useSongHeaders();

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            fetchSongs(currentPage, pageSize, searchQuery, setLoading, setTotalPages, setSongsData, "0");
        }, 600);
        return () => clearTimeout(debounceTimer);
    }, [searchQuery, currentPage, pageSize]);  

    const handleDelete = async () => {
        closeModal();
        if (!selectedSongId) return;
        const response = await deleteSong(selectedSongId);

        if (response === true) {
            fetchSongs(currentPage, pageSize, searchQuery, setLoading, setTotalPages, setSongsData, "0");
        }
    };
    
    const handleSearchChange = (query: string) => {
        setSearchQuery(query);
        setCurrentPage(1);
    };

    const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPageSize(Number(event.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handleEdit = (songId: number) => {
        navigate(`/songs/form/${songId}`);
    };

    const openDeleteModal = (songId: number) => {
        setSelectedSongId(songId);
        setDeleteModalOpen(true);
    };

    const closeModal = () => {
        setDeleteModalOpen(false);
    };

    const gotoAddSongForm = () => {
        navigate('/songs/form')
    }

    return (
        <SectionContainer title="Song Library" rightElement={<GeneralButton onClick={gotoAddSongForm} text="Add song +" />}>
            <DeleteModal isOpen={isDeleteModalOpen} onClose={closeModal} onConfirm={handleDelete}>
                <p className="mt-1">Are you sure you want to delete this Song? it can not be undone</p>
            </DeleteModal>
            <SearchInput placeholder="Search songs..." onSearchChange={(query) => handleSearchChange(query)} />
            <div className="overflow-x-auto mt-3">
                <DynamicTable data={songsData} headers={headers} renderRow={(song, index) => ( 
                    <SongDataTable key={song.id} song={song} handleEdit={() => handleEdit(song.id)} openDeleteModal={() => openDeleteModal(song.id)}/>
                )}
                loading={loading} />
                {songsData.length > 0 && ( <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} onPageSizeChange={handlePageSizeChange} pageSize={pageSize} />)}
            </div>
        </SectionContainer>
    );
};

export default SongScreen;
