import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';

interface StepComponentProps {
  stepNumber: number;
  title: string;
  isExpanded: boolean;
  isComplete: boolean;
  onToggle: () => void;
  children: React.ReactNode;
}

const StepComponent: React.FC<StepComponentProps> = ({stepNumber, title, isExpanded, isComplete, onToggle, children}) => {
  return (
    <div className="border-2 border-honeydew p-4 shadow rounded-3xl mt-5">
      <div onClick={onToggle} className="cursor-pointer flex items-center justify-between">
        <div className="flex items-center">
          <span className="inline-flex items-center justify-center rounded-full bg-flame text-white text-center w-10 h-10">
            {stepNumber}
          </span> 
          <span className='text-honeydew ml-5'>{title}</span>
        </div>
        <div className="text-2xl">
          {isComplete ? (
            <FaCheck className="text-flame" />
          ) : (
            <FaTimes className="text-flame" />
          )}
        </div>
      </div>
      
      <div className={`overflow-y-auto transition-all duration-500 ${isExpanded ? 'max-h-[90vh]' : 'max-h-0'}`}>
        {children}
      </div>
    </div>
  );
};

export default StepComponent;