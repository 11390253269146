import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../../global/storage/userContext';
import ReactMarkdown from 'react-markdown';
import DOMPurify from 'dompurify';
import gfm from 'remark-gfm';
import highlight from 'rehype-highlight';
import 'github-markdown-css';
import StepComponent from './componets/stepComponent';
import BasicInfoForm from './componets/basicInfo';
import EmailVerificationForm from './componets/emailVerifcation';
import SocietyStep from './componets/societyStep';
import PreviousPublisherStep from './componets/previousPublisherStep';

const AccountSetUpInfoPage: React.FC = () => {
    const { signup, user, setUser, login } = useUser();
    const [formData, setFormData] = useState({ artistName:'', firstName: '', lastName: '', email: '', password: '', confirmPassword: '', tos: false});
    const [message, setMessage] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [expandedIds, setExpandedIds] = useState<string[]>(['step1']);
    const [currentStep, setCurrentStep] = useState(1);
    const [userId, setUserId] = useState<number | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        loadTosContent();
        handleVerificationToken();
    }, []);

    const handleVerificationToken = async () => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');
        const step = searchParams.get('step');

        if (token && step === '3') {
            try {
                const response = await fetch(`/api/user/verify-email/${token}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
                const data = await response.json();
                if (response.ok) {
                    setUser(data.user);
                    setCurrentStep(3);
                    setExpandedIds(['step3']);
                    setMessage('Email verified successfully!');
                } else {
                    setMessage(data.error || 'Email verification failed.');
                }
            } catch (error) {
                console.error('Error during email verification:', error);
                setMessage('An error occurred during email verification.');
            }
        }
    };

    const loadTosContent = async () => {
        try {
            const response = await fetch('/assets/files/terms-and-conditions.md');
            if (!response.ok) {
                throw new Error('Failed to load markdown file');
            }
            const markdownContent = await response.text();
            const sanitizedContent = DOMPurify.sanitize(markdownContent);
            setModalContent(sanitizedContent);
        } catch (error) {
            console.error('Error loading markdown file:', error);
        }
    };

    const toggleExpand = (id: string) => {
        setExpandedIds((currentIds) => {
          if (currentIds.includes(id)) {
            return [];
          }
          return [id];
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
        setMessage('');
    };

    const handleViewTos = () => {
        setShowModal(true);
    };

    const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.currentTarget === event.target) {
            setShowModal(false);
        }
    };

    const handleSignup = async () => {
        if (isStep1Complete()) {
            try {
                const user = await signup(formData);
                setUserId(user.id);
                setCurrentStep(2);
                setExpandedIds(['step2']);
            } catch (error: any) {
                setMessage(error.message || 'An error occurred during signup');
            }
        } else {
            setMessage('Please complete all required fields and accept the Terms of Service');
        }
    };

    const handleVerificationSent = () => {
        setMessage('Verification email sent. Please check your inbox.');
        setCurrentStep(5);
        setExpandedIds(['step5']);
    };

    const handleSkip = (currentStep: number) => {
        setCurrentStep(currentStep);
        setExpandedIds([`step${currentStep}`]);
    };

    const isStep1Complete = () => {
        return formData.firstName.trim() !== '' &&
               formData.lastName.trim() !== '' &&
               formData.email.trim() !== '' &&
               formData.password.trim() !== '' &&
               formData.confirmPassword.trim() !== '' &&
               formData.password === formData.confirmPassword &&
               formData.tos;
    };

    const isStep2Complete = () => {
        return false;
    };

    const isStep3Complete = () => {
        return user?.verified === true;
    };

    const isStep4Complete = () => {
        // For now, Step 3 is always considered incomplete until the user clicks the button
        return false;
    };

    const handleCompletionAndRedirect = async () => {
        try {
            await login(formData.email, formData.password);
            navigate('/');
        } catch (error) {
            console.error('Error logging in:', error);
            setMessage('An error occurred while logging in. Please try again.');
        }
    };

    return (
        <div className="min-h-screen bg-black flex items-center justify-center">
            <div className="w-full max-w-5xl">
                <div className="bg-DarkNight p-8 rounded-lg shadow-sm shadow-eerieBlack">
                    <h1 className="text-2xl font-bold mb-2 text-center text-honeydew">Welcome to <span className='text-flame'>thefidia</span></h1>
                    <p className='text-lg text-center text-honeydew'>We secure the <span className='text-flame'>royalties</span> you deserve for your music.</p>
                    <p className='text-md text-center text-honeydew'>Let's get you started on your account set up.</p>
                    {message && <div className="text-red-600 text-sm pt-1 text-center">{message}</div>}

                    <StepComponent stepNumber={1} title="Basic Information" isExpanded={expandedIds.includes('step1')} isComplete={isStep1Complete()} onToggle={() => toggleExpand('step1')}>
                        <BasicInfoForm formData={formData} handleChange={handleChange} handleNextStep={handleSignup} isEditable={currentStep === 1} tosContent={modalContent} handleViewTos={handleViewTos}/>
                    </StepComponent>

                    <StepComponent stepNumber={2} title="Society Information" isExpanded={expandedIds.includes('step2')} isComplete={isStep2Complete()} onToggle={() => toggleExpand('step2')}>
                        {userId ? (
                            <SocietyStep userId={userId} handleNextStep={() =>handleSkip(3)} onSkip={() => handleSkip(3)} />
                        ) : (
                            <p className="text-sm text-honeydew mb-4 mt-5">Please complete the previous steps first.</p>
                        )}
                    </StepComponent>

                    <StepComponent stepNumber={3} title="Previous Publisher" isExpanded={expandedIds.includes('step3')} isComplete={isStep3Complete()} onToggle={() => toggleExpand('step3')}>
                        {userId ? (
                            <PreviousPublisherStep userId={userId} handleNextStep={() => handleSkip(4)} onSkip={() => handleSkip(4)} />
                        ) : (
                            <p className="text-sm text-honeydew mb-4 mt-5">Please complete the previous steps first.</p>
                        )}
                    </StepComponent>

                    <StepComponent stepNumber={4} title="Verify your email" isExpanded={expandedIds.includes('step4')} isComplete={isStep4Complete()} onToggle={() => toggleExpand('step4')}>
                        {userId && !user?.verified ? (
                            <EmailVerificationForm userId={userId} onVerificationSent={handleVerificationSent} onSkip={() => handleSkip(5)} />
                        ) : user?.verified ? (
                            <p className="text-sm text-honeydew mb-4 mt-5">Email verified successfully!</p>
                        ) : (
                            <p className="text-sm text-honeydew mb-4 mt-5">Please complete the previous steps first.</p>
                        )}
                    </StepComponent>

                    <StepComponent stepNumber={5} title="Complete Signup" isExpanded={expandedIds.includes('step5')} isComplete={isStep4Complete()} onToggle={() => toggleExpand('step5')}>
                        <div className="space-y-4">
                            {userId ? (
                                <p className="text-sm text-honeydew mb-4 mt-5">
                                    You're almost done! We've sent a verification email to your inbox. Click the button below to complete your signup and go to your dashboard.
                                </p>
                            ) : (
                                <p className="text-sm text-honeydew mb-4 mt-5">Please complete the previous steps first.</p>
                            )}
                            {userId && 
                                <button onClick={handleCompletionAndRedirect} className="w-full py-2 rounded-lg bg-flame hover:bg-red-600 text-white font-medium">
                                    Complete Signup and Go to Dashboard
                                </button>
                            }
                        </div>
                    </StepComponent>

                    {showModal && (
                        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 overflow-y-auto h-full w-full z-50" onClick={handleBackdropClick}>
                            <div className="relative mx-auto p-8 w-11/12 max-w-7xl max-h-3/4 bg-gray-800 text-white shadow-lg rounded-md overflow-y-auto">
                                <div className="mt-3 markdown-body p-8 space-y-4">
                                    <ReactMarkdown className="prose max-w-none" remarkPlugins={[gfm]} rehypePlugins={[highlight]} children={modalContent} />
                                    <button onClick={() => setShowModal(false)} className="w-44 ml-1 md:ml-0 md:w-24 p-1 md:p-2 rounded-xl bg-flame hover:bg-lightFlame flex items-center justify-center">
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="text-honeydew text-md mb-8 mt-8 text-center">
                        Already have an account? <a href="/login" className="text-flame hover:text-blue-600">Login</a>
                    </div>

                    <div className="hidden md:flex fixed bottom-0 right-0 z-50 p-4 md:p-8">
                        <div className="text-3xl text-honeydew flex items-center">
                            <img src="/assets/fidiaLogo2.png" alt="thefidia" className="h-10 mr-2" />
                            <h1 className="flex items-center" style={{ fontFamily: 'serifReclare, serif' }}> theFidia </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountSetUpInfoPage;

