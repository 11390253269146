import React from 'react';
import { SongRowProps } from '../../utlis/types/songTypes';

const RecentSongTable: React.FC<SongRowProps> = ({ song }) => {
    const createdAt = song.createdAt ? new Date(song.createdAt).toISOString().split('T')[0] : 'N/A';
    
    return (
        <tr className="hover:bg-gray-200 dark:hover:bg-Night">
            <td className="border-t px-4 py-2 max-w-xs whitespace-normal overflow-hidden rounded-l-md">
                <div className="flex flex-col">
                    <span className="font-semibold">{song.title}</span>
                    <span className="text-sm text-gray-600 dark:text-gray-400">{song.performingArtist}</span>
                </div>
            </td>
            <td className="border-t px-4 py-2 text-right whitespace-nowrap rounded-r-md">{createdAt}</td>
        </tr>
    );
}

export default RecentSongTable;
