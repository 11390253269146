import React, { useState } from "react";
import MonthlyLineChart from "../utlis/graphs/monthlyLineChart";
import QuarterlyBarChart from "../utlis/graphs/quarterlyBarChart";
import { GraphData, Song, songGraphHeaders } from "../utlis/types/GraphTypes";
import DynamicTable from "../../../../global/dataTable/table";
import RoyaltiesSongDataTable from "./royaltiesSongDataTable";
import Pagination from "../../../../global/dataTable/pagination";
import { SectionContainer } from "../../../../global/ui/containers";

const RoyaltiesScreen: React.FC = () => {
    const [songsData, setSongsData] = useState<Song[]>([]);
    const [monthlyData, setMonthlyData] = useState<GraphData[]>([]);
    const [quarterlyData, setQuarterlyData] = useState<GraphData[]>([]);
    
    const [chartType, setChartType] = useState("line");
    const [infoType, setInofType] = useState("TopSongs");

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [totalPages, setTotalPages] = useState(0);

    const [loading, setLoading] = useState(false);

    const topSongs = songsData.sort((a, b) => b.earnings - a.earnings).slice(0, 5);

    const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPageSize(Number(event.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    return ( 
        <SectionContainer title="Royalties">
            {/* Top half */}
            <div className="lg:ml-3 mb-6 border-b">
                <button className={`cursor-pointer p-3 rounded-t-md ${chartType === 'line' ? 'bg-flame text-white' : 'hover:bg-gray-400 dark:hover:bg-gray-700'}`} onClick={() => setChartType("line")}>Monthly View</button>
                <button className={`cursor-pointer p-3 rounded-t-md ${chartType === 'bar' ? 'bg-flame text-white' : 'hover:bg-gray-400 dark:hover:bg-gray-700'}`} onClick={() => setChartType("bar")}>Quarterly View</button>
            </div>
            <div className="h-96 -ml-12">
                {chartType === "line" ? <MonthlyLineChart data={monthlyData} /> : <QuarterlyBarChart data={quarterlyData} />}
            </div>
            <div className="lg:ml-3 mb-6 border-b"></div>

            {/* Bottom half */}
            <div className="mt-5 flex flex-wrap ml-3">
                <button type="button" className="w-full focus:outline-none lg:w-1/4 pr-1" onClick={() => setInofType("TopSongs")}>
                    <div className="w-full rounded-t-xl bg-gradient-to-b from-flame to-white dark:to-eerieBlack z-10 text-white">
                        <div className="p-4 h-full flex items-start flex-col">
                            <h1 className="text-md xl:text-xl lg:mb-1 lg:px-3">Top Songs</h1>
                            <table className="w-full text-left">
                                <tbody>
                                    {topSongs.length > 0 ? (
                                        topSongs.map(song => (
                                            <tr key={song.id}>
                                                <td className="px-4 py-2">{song.title}</td>
                                                <td className="px-4 py-2">{song.earnings}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={2} className="text-center py-10">No data available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </button>
                <button type="button" className="w-full focus:outline-none lg:w-1/4 pr-1" onClick={() => setInofType("TopIncomeSources")}>
                    <div className="w-full rounded-t-xl bg-gradient-to-b from-flame to-white dark:to-eerieBlack z-10 text-white">
                        <div className="p-4 h-full flex items-start flex-col">
                            <h1 className="text-md xl:text-xl lg:mb-1 px-3">Income</h1>
                            <table className="w-full text-left">
                                <tbody>
                                    {topSongs.length > 0 ? (
                                        topSongs.map(song => (
                                            <tr key={song.id}>
                                                <td className="px-4 py-2">{song.title}</td>
                                                <td className="px-4 py-2">{song.earnings}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={2} className="text-center py-10">No data available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </button>
            </div>

            <div className="overflow-x-auto mt-3 ml-3">
                {infoType === "TopSongs" ?
                    <>
                        <DynamicTable data={songsData} headers={songGraphHeaders} renderRow={(song, index) => ( <RoyaltiesSongDataTable key={song.id} song={song}/>)} loading={loading} />
                        {songsData.length > 0 && ( <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} onPageSizeChange={handlePageSizeChange} pageSize={pageSize} />)}   
                    </>
                    :
                    <>
                    </>
                }
            </div>
        </SectionContainer>
    );
};
  
export default RoyaltiesScreen;  