import React, { useEffect, useRef, useState } from 'react';

interface Option {
 label: string;
 value: string;
}

interface CustomDropdownProps {
    value: string | string[];
    onChange: (value: string) => void;
    options: Option[];
    id: string;
    style?: React.CSSProperties;
    placeholder?: string;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({ value, onChange, options, id, style, placeholder }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [filter, setFilter] = useState("");
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    const selectedOptionLabel = options.find(option => option.value === value)?.label || '';

    const filteredOptions = options.filter(option =>
        option.label.toLowerCase().includes(filter.toLowerCase())
    );

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const selectOption = (option: Option) => {
        onChange(option.value);
        setIsOpen(false);
        setFilter('');
    };

    const clearInput = () => {
        onChange('');
        setFilter('');
        setIsOpen(false);
    };

    return (
        <div ref={dropdownRef} className="relative" style={style}>
            <div className="relative">
                <input type="text" id={id} className="border border-flame rounded-md bg-gray-200 dark:bg-Night p-2 pl-5 px-4 w-full pr-8"
                    value={selectedOptionLabel} onChange={e => {setFilter(e.target.value); onChange(e.target.value);}}
                    onClick={toggleDropdown} placeholder={placeholder} autoComplete="off"
                />
                {selectedOptionLabel && (
                    <button className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700" onClick={clearInput}>✕</button>
                )}
            </div>
            {isOpen && (
                <ul className="absolute z-10 bg-gray-200 dark:bg-Night border border-flame rounded-md w-full max-h-40 overflow-auto">
                    {filteredOptions.map((option, index) => (
                        <li key={index} className="p-2 hover:bg-lightFlame cursor-pointer" onClick={() => selectOption(option)}>
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CustomDropdown;
