import { UserFormProps, Users } from "../types/userTypes";

export const fetchUsers = async (page: number, pageSize: number, searchQuery: string, auth: string, setLoading: (loading: boolean) => void, setTotalPages: (totalPages: number) => void, setUsersData: (users: Users[]) => void ) => {
    try {
        const response = await fetch(`/api/user?page=${page}&pageSize=${pageSize}&search=${encodeURIComponent(searchQuery)}&auth=${auth}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setUsersData(data.users);
        setTotalPages(data.totalPages);
    } catch (error) {
        console.error(error);
    }
    setLoading(false);
}

export const formSubmit = async (formData: UserFormProps['formData'], setError: (newData: string) => void, endpoint: string, method:string) => {
    try {
        const response = await fetch(endpoint, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ formData }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            setError(errorData.error || 'Failed to save user');
            throw new Error(errorData.error || 'Failed to save user');
        }

        return response.ok;
    } catch(error: any) {
        setError("An error occurred while saving the user: " + error.message);
    }
}

export const deleteUser = async (selectedUserId: number) => {
    try {
        const response = await fetch(`/api/user/${selectedUserId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        return response.ok;
    } catch (error) {
        console.error('Error deleting user:', error);
    }
}