import React from 'react';

const LoadingScreen: React.FC = () => {
    return (
        <>
            <div className="fixed top-0 left-0 z-50 flex h-screen w-screen items-center justify-center bg-eerieBlack">
                <div className="text-3xl ml-7 md:ml-0">
                    <div className="inline-block animate-bounce mr-10 text-flame">♪</div>
                    <div className="inline-block animate-bounce animation-delay-200 mr-10 text-white">♫</div>
                    <div className="inline-block animate-bounce animation-delay-400 mr-10 text-flame">♬</div>
                    <div className="inline-block animate-bounce animation-delay-600 mr-10 text-white">♫</div>
                    <div className="inline-block animate-bounce animation-delay-800 mr-10 text-flame">♪</div>
                </div>
            </div>
            <div className="fixed bottom-0 right-0 z-50 p-4 md:p-8">
                <div className="text-3xl text-transparent bg-clip-text animate-gradient flex items-center">
                    <img src="/assets/fidiaLogo2.png" alt="thefidia" className="h-10 mr-2" />
                    <h1 className="flex items-center" style={{ fontFamily: 'serifReclare, serif' }}>
                        theFidia
                        <span className="inline-block w-2"></span>
                        <span className="inline-block animate-bounce animation-delay-200 text-flame">.</span>
                        <span className="inline-block animate-bounce animation-delay-400 text-white">.</span>
                        <span className="inline-block animate-bounce animation-delay-600 text-flame">.</span>
                        <span className="inline-block animate-bounce animation-delay-800 text-white">.</span>
                        <span className="inline-block animate-bounce animation-delay-1000 text-flame">.</span>
                    </h1>
                </div>
            </div>
        </>
    );
};

export default LoadingScreen;
