import React, { useState, useEffect } from 'react';
import { WriterFormProps, ProOptions } from '../utlis/types/wrtiterTypes';
import CustomDropdown from '../../../../global/ui/customDropdown';
import { InfoBanner } from '../../../../global/ui/banners';
import { GeneralButton } from '../../../../global/ui/buttons';

const WriterForm: React.FC<WriterFormProps> = ({ formData, handleSubmit, handleInputChange, error, success, closeModal }) => {
    const [isCaeIpiNumberRequired, setIsCaeIpiNumberRequired] = useState(false);
    const [formError, setFormError] = useState('');
    
    useEffect(() => {
        if (formData.Pro) {
            setIsCaeIpiNumberRequired(true);
        } else {
            setIsCaeIpiNumberRequired(false);
        }
    }, [formData.Pro]);

    const handleStandardInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleInputChange(e.target.name, e.target.value);
    };

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isCaeIpiNumberRequired && !formData.caeIpiNumber) {
            setFormError('CAE/IPI Number is required for PRO Membership.');
            return;
        }
        setFormError('');
        handleSubmit(e);
    };

    return (
        <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleFormSubmit(e)} className="space-y-6">
            {error && <InfoBanner message={error} />}
            {formError && <InfoBanner message={formError} />}
            {success && <InfoBanner message={success} />}
            <div className="flex flex-col md:flex-row md:items-center">
                <label htmlFor="firstName" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0 md:ml-20 lg:ml-52 text-black dark:text-white">First Name<span className="text-red-500 text-base ml-1">*</span></label>
                <input type="text" id="firstName" name="firstName" className="border border-flame rounded-md bg-gray-200 dark:bg-Night p-2 px-4 w-full md:w-2/5" value={formData.firstName} onChange={handleStandardInputChange} />
            </div>
            <div className="flex flex-col md:flex-row md:items-center">
                <label htmlFor="lastName" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0 md:ml-20 lg:ml-52 text-black dark:text-white">Last Name<span className="text-red-500 text-base ml-1">*</span></label>
                <input type="text" id="lastName" name="lastName" className="border border-flame rounded-md bg-gray-200 dark:bg-Night p-2 px-4 w-full md:w-2/5" value={formData.lastName} onChange={handleStandardInputChange} />
            </div>
            <div className="flex flex-col md:flex-row md:items-center">
                <label htmlFor="Pro" className="md:w-1/4 lg:w-1/6 mb-3 md:mb-0 md:ml-20 lg:ml-52 text-black dark:text-white">PRO Membership</label>
                <CustomDropdown value={formData.Pro} onChange={(value) => handleInputChange('Pro', value)} options={ProOptions} id={"Pro"}/>
            </div>
            <div className="flex flex-col md:flex-row md:items-center">
                <label htmlFor="caeIpiNumber" className="md:w-1/4 lg:w-1/6 mb-6 md:mb-0 md:ml-20 lg:ml-52 text-black dark:text-white">CAE/IPI Number
                    {isCaeIpiNumberRequired && <span className="text-red-500 text-base ml-1">*</span>}
                </label>
                <input type="text" id="caeIpiNumber" name="caeIpiNumber" className={`border ${isCaeIpiNumberRequired ? 'border-red-500' : 'border-flame'} rounded-md bg-gray-200 dark:bg-Night p-2 px-4 w-full md:w-2/5`} value={formData.caeIpiNumber} onChange={handleStandardInputChange} />
            </div>
            <div className="flex justify-end items-center mb-10">
                <GeneralButton type="button" onClick={closeModal} text="Cancel" space={true} />
                <GeneralButton type="submit" text="Submit" space={true}/>
            </div>
        </form>
    );
}

export default WriterForm;
