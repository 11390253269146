import React, { ReactNode } from 'react';

interface DeleteModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    children: ReactNode;
}

const DeleteModal: React.FC<DeleteModalProps>  = ({ isOpen, onClose, onConfirm, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
            <div className=" bg-gray-300 dark:bg-eerieBlack p-4 rounded-lg shadow-lg max-w-sm w-full text-center">
                <div className="mb-4">{children}</div>
                <div className="flex justify-center">
                    <button onClick={onClose} className="bg-gray-500 dark:bg-gray-300 hover:bg-gray-400 text-black py-2 px-4 rounded mr-2">
                        Cancel
                    </button>
                    <button onClick={onConfirm} className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteModal;
