import React from 'react';

interface InfoBannerProps {
  message: string;
  actionLabel?: string;
  onAction?: () => void;
}

interface AlertBannerProps {
    message: string;
    title?: string;
    onClose?: () => void;
}

export const InfoBanner: React.FC<InfoBannerProps> = ({ message, actionLabel, onAction }) => {
  if (!message) return null;

  return (
    <div className="bg-flame text-white p-4 mb-6 rounded-lg shadow-md">
      <div className="flex justify-between items-center">
        <p className="font-semibold">{message}</p>
        {actionLabel && onAction && (
          <button onClick={onAction} className="bg-white text-flame px-4 py-2 rounded-md hover:bg-gray-100 transition-colors">
            {actionLabel}
          </button>
        )}
      </div>
    </div>
  );
};

export const AlertBanner: React.FC<AlertBannerProps> = ({ message, title, onClose }) => {
  if (!message) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-gray-300 dark:bg-eerieBlack py-4 px-10 rounded-lg shadow-lg max-w-sm md:max-w-xl lg:max-w-7xl w-full ">
        <div className="flex justify-between items-center mb-10">
            <h2 className="text-left text-2xl text-flame">{title}</h2>
            <button className="text-flame text-2xl" onClick={onClose}>&times;</button>
        </div>
        <div className="">{message}</div>
      </div>
    </div>
  );
}

export const LogoBanner: React.FC = () => {
  return (
    <div className="fixed bottom-0 right-0 z-50 p-4 md:p-8">
      <div className="text-3xl text-honeydew flex items-center">
        <img src="/assets/fidiaLogo2.png" alt="thefidia" className="h-10 mr-2" />
        <h1 className="flex items-center" style={{ fontFamily: 'serifReclare, serif' }}> theFidia </h1>
      </div>
    </div>
  );
}