import { Job } from "../types/jobtypes";

export const fetchJobDropdown = async (setJobDropdown: React.Dispatch<React.SetStateAction<Job[]>>) => {
    try {
        const response = await fetch('/api/jobs');
        if (!response.ok) {
            throw new Error('Failed to fetch jobs');
        }
        const data = await response.json();
        setJobDropdown(data);
    } catch (error) {
        console.error('Error fetching jobs:', error);
    }
};

export const createJob = async (jobData: { name: string; description: string; type: string }) => {
    try {
        const response = await fetch('/api/jobs/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(jobData),
        });
        if (!response.ok) {
            throw new Error('Failed to create job');
        }
        return await response.json();
    } catch (error) {
        console.error('Error creating job:', error);
        throw error;
    }
};

export const runJob = async (jobId: string) => {
    try {
        const response = await fetch(`/api/jobs/${jobId}/run`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error('Failed to run job');
        }
        return await response.json();
    } catch (error) {
        console.error('Error running job:', error);
        throw error;
    }
};