export interface Users {
    id: number;
    artistName: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    account_type: string;
}

export interface    UserFormProps {
    formData: { 
        firstName: string;
        lastName: string;
        email: string;
        password: string;
        account_type: string;
    };
    handleSubmit: React.FormEventHandler<HTMLFormElement>;
    handleInputChange: (field: string, value: string) => void;
    message: string;
    closeModal: () => void;
    setMessage: (message: string) => void;
    editing: boolean;
}

export interface UserRowProps {
    users: Users;
    handleEdit: (user: number) => void;
    openDeleteModal: (id: number) => void;
}

export const userHeaders = [
    { name: "Name" },
    { name: "Email" },
    { name: "Account Type" },
    { name: "Actions" },
];

export const authOptions = [
    { label: 'Admin', value: 'admin' },
    { label: 'Developer', value: 'developer' },
    { label: 'User', value: 'user' },
];