import React, { useState, useEffect } from "react";
import { Job } from "../utlis/types/jobtypes";
import { fetchJobDropdown, createJob, runJob } from "../utlis/services/jobpageFunctions";
import CustomDropdown from "../../../../global/ui/customDropdown";
import { GeneralButton } from "../../../../global/ui/buttons";
import { useUser } from "../../../../global/storage/userContext";
import FormModal from "../../../../global/modals/formModal";
import JobForm from "./jobForm";
import { InfoBanner } from "../../../../global/ui/banners";
import { SectionContainer } from "../../../../global/ui/containers";

const JobsScreen: React.FC = () => {
    const { user } = useUser();
    const [jobDropdown, setJobDropdown] = useState<Job[]>([]);
    const [selectedJob, setSelectedJob] = useState<Job | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({ name: '', description: '', type: '' });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isRunning, setIsRunning] = useState(false);
    const jobOptions = jobDropdown.map(job => ({ label: job.name, value: job.id }));

    useEffect(() => {
        fetchJobDropdown(setJobDropdown);
    }, []);

    const handleJobSelect = (jobId: string) => {
        const job = jobDropdown.find(j => j.id === jobId);
        setSelectedJob(job || null);
    };

    const handleRunJob = async () => {
        if (!selectedJob) return;
        setIsRunning(true);
        setError('');
        try {
            const result = await runJob(selectedJob.id);
            setSuccess('Job completed successfully');
            setTimeout(() => {
                setSuccess('');
            }, 3000);
            setSelectedJob(prev => prev ? {...prev, status: result.jobStatus} : null);
            setJobDropdown(prev => prev.map(job => 
                job.id === selectedJob.id ? {...job, status: result.jobStatus} : job
            ));
            console.log(selectedJob);
        } catch (error) {
            setError('Failed to run job');
            setTimeout(() => {
                setError('');
            }, 3000);
        } finally {
            setIsRunning(false);
        }
    }

    const handleInputChange = (name: string, value: string) => {
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        try {
            const newJob = await createJob(formData);
            setJobDropdown(prev => [...prev, newJob]);
            setSuccess('Job created successfully');
            closeModal();
        } catch (error) {
            setError('Failed to create job');
        }
    }

    const closeModal = () => {
        setIsModalOpen(false);
        setFormData({ name: '', description: '', type: '' });
        setError('');
        setSuccess('');
    }
    
    return (
        <SectionContainer title="Jobs" rightElement={user?.auth === "developer" && <GeneralButton text="Create Job +" onClick={() => setIsModalOpen(true)} />}>
            {isRunning && <InfoBanner message="Running Job..." />}
            {error && <InfoBanner message={error} />}
            {success && <InfoBanner message={success} />}
            <CustomDropdown value={selectedJob?.id || ''} onChange={handleJobSelect} options={jobOptions} placeholder="Select a Job" id="Job"/>
            <FormModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title="Create Job"
                content={
                    <JobForm formData={formData} handleSubmit={handleSubmit} handleInputChange={handleInputChange} error={error}  success={success} closeModal={closeModal} />
                }
                editing={false}
            />
            <div className="h-2"></div>
            {selectedJob && (
                <div className="flex flex-col items-center">
                    <div className="mt-4">{selectedJob.description}</div>
                    <div className="mt-2">Status: {selectedJob.status}</div>
                    <div className="h-2"></div>
                    <GeneralButton text="Run Job" onClick={handleRunJob} disabled={!selectedJob} />
                </div>
            )}
        </SectionContainer>
    );
};

export default JobsScreen;