import React, { useState, useEffect } from 'react';
import { useUser } from '../../../../../global/storage/userContext';
import { ToggleButton } from '../../../../../global/ui/buttons';

const DisplaySection: React.FC = () => {
    const { user, toggleTheme } = useUser();
    const [isDarkMode, setIsDarkMode] = useState(user?.settings.DarkMode ?? true);

    useEffect(() => {
        setIsDarkMode(user?.settings.DarkMode ?? true);
    }, [user]);

    const toggleDarkMode = () => {
        toggleTheme();
    };

    return (
        <div>
            <div className="flex justify-between items-center text-sm">
                <p>Display mode (<span className="text-sm font-medium">{isDarkMode ? 'Dark' : 'Light'}</span>)</p>
                <ToggleButton toggleValue={isDarkMode} onClick={toggleDarkMode} />
            </div>
        </div>
    );
};

export default DisplaySection;
