import React from 'react';
import { useUser } from './storage/userContext';


const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { theme } = useUser();

  return (
    <div className={`${theme === 'dark' ? 'dark' : ''}`}>
      {children}
    </div>
  );
};

export default ThemeProvider;