import React from 'react';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (newPage: number) => void;
    onPageSizeChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    pageSize: number;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange, onPageSizeChange, pageSize }) => {
    const handlePageChange = (direction: 'prev' | 'next') => {
        if (direction === 'next' && currentPage < totalPages) {
            onPageChange(currentPage + 1);
        } else if (direction === 'prev' && currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    return (
        <div className="flex flex-col md:flex-row items-center justify-between mt-4 mb-2">
            <div>
                <button onClick={() => handlePageChange('prev')} disabled={currentPage === 1} className={`text-xs md:text-base mx-1 px-4 py-2 rounded-md ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : 'hover:text-flame'}`}>
                    &#8592; Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={() => handlePageChange('next')} disabled={currentPage === totalPages} className={`text-xs md:text-base mx-1 px-4 py-2 rounded-md ${currentPage === totalPages ? 'cursor-not-allowed opacity-50' : 'hover:text-flame'}`}>
                    Next &#8594;
                </button>
            </div>
            <div>
                <label htmlFor="page-size">Show: </label>
                <select id="page-size" value={pageSize} onChange={onPageSizeChange} className="border border-flame rounded-md bg-white dark:bg-Night p-1 md:p-2 md:px-4">
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
            </div>
        </div>
    );
};

export default Pagination;